.home-testimonials {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 0 8vh 0;
  font-weight: bold;
  user-select: none;

  .testimonials-preview-title {
    font-size: 2.3vw;
    font-weight: normal;
    letter-spacing: 0.3vw;
    margin: 4vh 0 4vh 0;
    text-align: center;
    text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
  }


  .testimonials-preview-carousel {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    height: 40vh;
    width: 100vw;

    .fad {
      position: absolute;

      color: #80824b;
      cursor: pointer;
      font-size: 2.5vw;
      transition: 0.2s;

      &.fa-chevron-left {
        left: 4.5vw;
      }

      &.fa-chevron-right {
        right: 4.5vw;
      }

      &:hover {
        color: #d4b466;
        filter: drop-shadow(0 0 0.2vh #d4b466);
      }
    }
  }

  .page-indicators {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    margin: 4vh 0 0 0;
  }

  button {
    margin: 4vh 0 0 0;
    width: 9vw;

    background-color: whitesmoke;
    border: 1px solid #80824b;
    color: #80824b;

    transition: 0.5s;

    &:hover {
      background-color: #80824b;
      border: 1px solid whitesmoke;
      color: whitesmoke;
    }
  }
}