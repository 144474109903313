
.content-box {
  display: flex;
  position: relative;
  flex-direction: column;

  background-color: #e6e6db;

  &:last-child {
    .content-box-content {
      box-shadow: unset;
    }
  }

  &.left {
    .content-box-title {
      text-align: left;
    }

    .content-box-content:before {
      transform: skewY(-5deg);
    }
  }

  .content-box-title {
    position: absolute;
    top: 0;
    height: 20vh;
    width: 100vw;
    overflow-x: hidden;

    color: whitesmoke;
    font-size: 4.5vw;
    font-weight: bolder;
    letter-spacing: 0.5vw;
    text-align: right;
    text-shadow: 0.2vw 0.2vw 0.5vw rgb(128, 130, 75, 0.9);
    text-transform: uppercase;

    div {
      padding: 5vh 1.5vw 0 1.5vw;
    }
  }

  .content-box-content {
    position: relative;
    margin: 25vh 0 0 0;
    top: 0;

    background-color: transparent;
    box-shadow: 2px 5px 5px 0px rgb(128 130 75 / 50%);
    z-index: 1;

    &:before {
      content: ' ';
      position: absolute;
      display: flex;
      flex-direction: column;
      height: calc(100% - 5vh);
      margin: 35vh 0 0 0;
      min-height: 20vh;
      top: -42vh;
      width: 100%;

      background-color: whitesmoke;
      box-shadow: -2px -5px 5px 0px rgba(128, 130, 75, 0.5);
      transform: skewY(5deg);
      z-index: -1;
    }

    &:after {
      content: ' ';
      position: absolute;
      height: 50%;
      width: 100%;
      bottom: 0;

      background-color: whitesmoke;
      z-index: -2;
    }
  }
}
