.why-coaching-preview-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #80824b;
  font-weight: bolder;
  user-select: none;

  .why-coaching-preview-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    .why-coaching-preview-title {
      font-size: 4vh;
      letter-spacing: 0.3vw;
      margin: 0 0 2vh 0;
      padding: 2vh 2vw 0 2vw;
      text-align: center;
      text-shadow: 0 0 0.3vw rgb(75, 78, 49, 1);
      width: 100%;
    }

    .why-coaching-preview-image-description {
      width: 95%;

      .why-coaching-preview-image {
        display: flex;
        justify-content: center;

        div {
          width: 95%;
        }
      }

      .why-coaching-preview-description {
        font-size: 2vh;
        text-align: center;
        margin: 2vh 5vw 0 5vw;
        color: #80824b;

        div {
          margin: 1vh 0 1vh 0;
          text-align: justify;
          text-align-last: center;
        }

        button {
          margin: 2vh 0 3vh 0;
          font-size: 2vh;
          width: 37vw;

          background-color: whitesmoke;
          border: 1px solid #80824b;
          color: #80824b;
        }
      }
    }


  }

}