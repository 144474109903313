.step-three-content-mobile {
  display: flex;
  flex-direction: column;
  user-select: none;
  overflow-x: hidden;

  background-color: whitesmoke;
  color: #80824b;


  .step-three-title-mobile {
    font-size: 2.7vh;
    font-weight: bold;
    letter-spacing: 0.4vw;
    text-align: center;
    text-shadow: 0.5vw 0.5vw 0.5vw rgb(164, 154, 105, 0.9);
    padding: 5vh 0 2vh 0;
  }

  .full-contact-form-intro-mobile {
    font-size: 2vh;
    font-weight: bold;
    text-align: center;
    padding: 1vh 5vw 2vh 5vw;
  }

  .full-contact-form-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input, textarea {
      border: 1px solid #80824b;
      font-size: 1.4vh;

      &:focus-visible {
        outline-color: #80824b;
      }

      &:hover {
        cursor: text;
      }

      &::placeholder {
        color: #c0c1a5;
        font-weight: normal;
      }
    }

    input[type='checkbox'] + label,
    input[type='radio'] + label {
      margin: 0 0 0 1vh;
    }

    .name-mobile {
      display: flex;
      flex-direction: row;

      .first-name-mobile {
        padding: 1vh 2vw 0 0;

        input {
          padding: 0.75vh 2vw 0.75vh 2vw;
          border-radius: 10px 5px 5px 5px;
          width: 35vw;
        }
      }

      .last-name-mobile {
        padding: 1vh 0 0 0;

        input {
          padding: 0.75vh 2vw 0.75vh 2vw;
          border-radius: 5px 10px 5px 5px;
          width: 48vw;
        }
      }
    }

    .contact-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

        .email-mobile {
          padding: 1vh 1vw 0 0;

          input {
            padding: 0.75vh 2vw 0.75vh 2vw;
            border-radius: 5px 5px 5px 5px;
            width: 90vw;
          }
        }

        .phone-mobile {
          padding: 1vh 0 0 0;

          input {
            padding: 0.75vh 2vw 0.75vh 2vw;
            border-radius: 5px 5px 5px 5px;
            width: 90vw;
          }
        }

      .location-mobile {
        padding: 1vh 0 0 0;

        input {
          padding: 0.75vh 2vw 0.75vh 2vw;
          border-radius: 5px 5px 5px 5px;
          width: 90vw;
        }
      }

      .contact-options-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        fieldset {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-radius: 5px 5px 5px 5px;
          border: 1px solid #80824b;
          font-weight: bold;
          font-size: 1.3vh;
          background-color: white;
          margin: 1vh 5vw 0 5vw;
          width: 90vw;
        }

        .contact-platforms-mobile {
          display: flex;
          flex-direction: column;
          padding: 0 1vw 0 0;
        }

        .contact-days-mobile {
          display: flex;
          flex-direction: column;
          padding: 0 1vw 0 1vw;
        }

        .contact-time-mobile {
          display: flex;
          flex-direction: column;
          padding: 0 1vw 0 0;
        }
      }
    }

    .further-info-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .previous-coaching-mobile {
        display: flex;
        flex-direction: column;

        fieldset {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-radius: 5px 5px 5px 5px;
          border: 1px solid #80824b;
          font-weight: bold;
          font-size: 1.3vh;
          background-color: white;
          margin: 1vh 5vw 0 5vw;
          width: 90vw;
        }
      }

      .current-coaching-mobile {
        padding: 1vh 0 0 0;

        textarea {
          padding: 0.75vh 2vw 0.75vh 2vw;
          border-radius: 5px 5px 5px 5px;
          width: 90vw;
          height: 7vh;
        }
      }

      .fun-fact-mobile {
        padding: 0.5vh 0 0 0;

        textarea {
          padding: 0.75vh 2vw 0.75vh 2vw;
          border-radius: 5px 5px 10px 10px;
          width: 90vw;
          height: 5vh;
        }

      }
    }

    .submit-form-button-mobile {
      align-self: center;

      button {
        margin: 2vh 0 5vh 0;
        padding: 0 5vw 0 5vw;
        font-size: 2vh;

        background-color: whitesmoke;
        border: 1px solid #80824b;
        color: #80824b;

        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      }

      :hover {
        background-color: #80824b;
        color: whitesmoke;
      }
    }
  }
}