.action-plans-page {
  .action-plans-page-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .align-right {
      text-align: right;
    }


    .outcomes-list-items {
      display: flex;
      flex-direction: column;
      gap: 1vh;
      margin: 0.7vh 0 2vh 0;

      font-size: 1.4vw;
      font-weight: bold;
      letter-spacing: 0.3vw;
      text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);


      .list-item-1 {
        padding: 0.1vh 3vw 0.4vh 3vw;
        background-image: linear-gradient(
                        to right,
                        whitesmoke,
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke
        );
        color: white;
      }

      .list-item-2 {
        padding: 0.1vh 3vw 0.4vh 3vw;
        background-image: linear-gradient(
                        to right,
                        whitesmoke,
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke
        );
        color: white;
      }

      .list-item-3 {
        padding: 0.1vh 3vw 0.4vh 3vw;
        background-image: linear-gradient(
                        to right,
                        whitesmoke,
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke
        );
        color: white;
      }

      .list-item-4 {
        padding: 0.1vh 3vw 0.4vh 3vw;
        background-image: linear-gradient(
                        to right,
                        whitesmoke,
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        whitesmoke,
                        whitesmoke,
                        whitesmoke

        );
        color: white;
      }

      .list-item-5 {
        padding: 0.1vh 3vw 0.4vh 3vw;
        background-image: linear-gradient(
                        to left,
                        whitesmoke,
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke
        );
        color: white;
      }

      .list-item-6 {
        padding: 0.1vh 3vw 0.4vh 3vw;
        background-image: linear-gradient(
                        to left,
                        whitesmoke,
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke
        );
        color: white;
      }

      .list-item-7 {
        padding: 0.1vh 3vw 0.4vh 3vw;
        background-image: linear-gradient(
                        to left,
                        whitesmoke,
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke
        );
        color: white;
      }

      .list-item-8 {
        padding: 0.1vh 3vw 0.4vh 3vw;
        background-image: linear-gradient(
                        to left,
                        whitesmoke,
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke
        );
        color: white;
      }

      .list-item-9 {
        padding: 0.1vh 3vw 0.4vh 3vw;
        background-image: linear-gradient(
                        to right,
                        whitesmoke,
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        rgb(128, 130, 75, 0.2),
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke,
                        whitesmoke
        );
        color: white;
      }
    }

    .action-plans-resilience {
      display: flex;
      flex-direction: column;

      .resilience-image-text {
        display: flex;
        flex-direction: row;

        .resilience-image {

        }

        .resilience-text-content {
          display: flex;
          flex-direction: column;
          margin: 2vh 5vw 5vh 0;
          gap: 2.2vh;
          max-width: 50%;

          font-size: 1.3vw;
          font-weight: bold;
          text-align: justify;
        }
      }

      .resilience-conclusion {
        display: flex;
        flex-direction: column;
        margin: 0 6vw 7vh 6vw;
        gap: 2vh;

        font-size: 2vw;
        font-weight: bold;
        letter-spacing: 0.3vw;
        text-align: center;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
        transition: 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          color: #d4b466;
          text-shadow: 0.2vw 0.2vw 0.2vw rgb(212, 180, 102, 0.9);
        }
      }
    }

    .action-plans-head-ahead {
      display: flex;
      flex-direction: column;

      .head-ahead-image-text {
        display: flex;
        flex-direction: row;


        .head-ahead-image {

        }

        .head-ahead-text-content {
          display: flex;
          flex-direction: column;
          margin: 5vh 0 7vh 5vw;
          gap: 2.2vh;
          max-width: 50%;

          font-size: 1.3vw;
          font-weight: bold;
          text-align: justify;
        }
      }

      .head-ahead-conclusion {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2vh 5vw 5vh 0;
        gap: 2vh;

        font-size: 2vw;
        font-weight: bold;
        letter-spacing: 0.3vw;
        text-align: center;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
        transition: 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          color: #d4b466;
          text-shadow: 0.2vw 0.2vw 0.2vw rgb(212, 180, 102, 0.9);
        }
      }
    }

    .action-plans-flow {
      display: flex;
      flex-direction: column;


      .flow-image-text {
        display: flex;
        flex-direction: row;


        .flow-image {

        }

        .flow-text-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 2vh 5vw 5vh 0;
          gap: 5vh;
          line-height: 3.2vh;
          max-width: 50%;

          font-size: 1.3vw;
          font-weight: bold;
          text-align: justify;
        }
      }

      .flow-conclusion {
        display: flex;
        flex-direction: column;
        margin: 4vh 6vw 7vh 6vw;
        gap: 2vh;

        font-size: 2vw;
        font-weight: bold;
        letter-spacing: 0.3vw;
        text-align: center;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
        transition: 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          color: #d4b466;
          text-shadow: 0.2vw 0.2vw 0.2vw rgb(212, 180, 102, 0.9);
        }
      }
    }
  }
}
