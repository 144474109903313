.homepage-mobile {
  .page-content-mobile {
    flex-grow: 1;
    background: whitesmoke;

    .text-content {
      z-index: 3;
    }

    .background-homepage {
      display: block;
      position: fixed;
      height: 100vh;
      width: 100vw;
      z-index: 2;

      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
      filter: blur(1px);
    }
  }

}
