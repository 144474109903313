
.coming-soon-page-mobile {
  display: inline-block;
  position: relative;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: whitesmoke;
  color: #a49a69;
  user-select: none;

  .background-portrait {
    display: block;
    position: relative;
    height: 100vh;
    width: 100vw;

    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.17;
  }

  .extra-paragraph-space {
    margin: 3vh 0 1.5vh 0;
  }

  .text-content {
    position: absolute;
    height: fit-content;
    block-size: fit-content;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;


    .banner {
      display: inline-block;
      position: relative;
      width: 100vw;

      .title {
        padding: 2vh 0 3vh 0;

        color: #a49a69;
        font-family: Megrim, monospace;
        font-size: 17vw;
        font-weight: lighter;
        text-align: center;
        text-shadow: 0 0 0.8rem rgb(164, 154, 105, 0.9);
      }

      .subtitle {
        position: relative;
        padding: 0 0 0 0;

        color: #a49a69;
        font-family: RalewayMedium, monospace;
        font-size: 4.15vw;
        text-align: center;
        font-weight: lighter;
      }

    }

    .description {
      display: inline-block;
      width: 100vw;

      .coming-soon {
        padding: 10vh 0 8vh 0;

        font-family: RalewayThin, monospace;
        font-size: 12vw;
        font-stretch: ultra-expanded;
        font-weight: lighter;
        text-align: center;
        text-shadow: 0.6vw 0.6vw 0.6vw rgb(164, 154, 105,0.7);
      }

      p {
        font-family: RalewayMedium, monospace;
        font-size: 3.2vw;
        padding: 0.75vh 0 0.75vh 0;
        text-align: center;
      }


    }

    .text-content:before {
      content: '';
      display: inline-block;

    }
  }

}
