.action-plans-page-mobile {
  .action-plans-page-content-mobile {
    .outcomes-list-items-mobile {
      gap: 1vh;
      margin: 0 15vw 2vh 15vw;
      text-align: center;

      font-size: 1.8vh;
      font-weight: bold;
      letter-spacing: 0.3vw;
      text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);

      i {
        color: #d4b466;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0);
      }
    }

    .action-plans-resilience-mobile {

      .resilience-text-content-mobile {
        display: flex;
        flex-direction: column;
        margin: 3vh 7vw 2vh 7vw;

        font-size: 2vh;
        font-weight: bold;
        text-align: justify;
        gap: 2vh;
      }

      .resilience-conclusion-mobile {
        display: flex;
        flex-direction: column;
        margin: 3vh 12vw 5vh 12vw;
        gap: 1vh;

        font-size: 2.3vh;
        font-weight: bold;
        letter-spacing: 0.4vw;
        text-align: center;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      }

    }

    .action-plans-head-ahead-mobile {

      .head-ahead-text-content-mobile {
        display: flex;
        flex-direction: column;
        margin: 3vh 7vw 2vh 7vw;

        font-size: 2vh;
        font-weight: bold;
        text-align: justify;
        gap: 2vh;
      }

      .head-ahead-conclusion-mobile {
        display: flex;
        flex-direction: column;
        margin: 3vh 12vw 5vh 12vw;
        gap: 1vh;

        font-size: 2.3vh;
        font-weight: bold;
        letter-spacing: 0.4vw;
        text-align: center;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      }

    }

    .action-plans-flow-mobile {

      .flow-text-content-mobile {
        display: flex;
        flex-direction: column;
        margin: 3vh 7vw 2vh 7vw;

        font-size: 2vh;
        font-weight: bold;
        text-align: justify;
        gap: 2vh;
      }

      .flow-conclusion-mobile {
        display: flex;
        flex-direction: column;
        margin: 3vh 12vw 5vh 12vw;
        gap: 1vh;

        font-size: 2.3vh;
        font-weight: bold;
        letter-spacing: 0.4vw;
        text-align: center;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      }
    }
  }
}
