.step-two-content {


  .step-two-title {
    padding: 10vh 0 2vh 0;
    font-size: 3.2vw;
    font-weight: normal;
    letter-spacing: 0.4vw;
    text-align: center;
    text-shadow: 0.15vw 0.15vw 0.15vw rgb(164, 154, 105, 0.9);
  }

    .choose-package-intro {
      display: flex;
      flex-direction: column;
      padding: 0 0 1vh 0;

      font-size: 1.5vw;
      font-weight: bold;
      text-align: center;
    }

    .choose-package {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 2vh 0 2vh 0;
      gap: 1vw;

      color: white;
      font-weight: bolder;

      .choose-express-lite, .choose-full-beyond {
        display: flex;
        flex-direction: row;
        gap: 1vw;

        .choose-package-option {
          position: relative;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: 0;
          align-items: center;
          text-align: center;
          font-size: 1.1vw;
          border: 2px solid whitesmoke;
          height: 25vh;

          // Reset button styles
          align-self: unset;
          border-radius: 0;
          margin: unset;
          color: unset;
          font-family: unset;
          font-weight: unset;
          letter-spacing: unset;

          transition: 0.5s;

          &:hover {
            cursor: pointer;
            border: 2px solid #80824b;

            .subtitle {
              color: whitesmoke;

              &::after {
                width: 100%;
                left: 0;
              }
            }
          }

          .ribbon {
            width: 150px;
            height: 150px;
            overflow: hidden;
            position: absolute;
            top: -10px;
            left: -10px;

            &::before,
            &::after {
              position: absolute;
              z-index: -1;
              content: '';
              display: block;
              border: 5px solid #424226;
              border-top-color: transparent;
              border-left-color: transparent;
            }

            &::before {
              top: 0;
              right: 0;
            }

            &::after {
              bottom: 0;
              left: 0;
            }

            span {
              position: absolute;
              display: block;
              width: 225px;
              padding: 15px 0;
              background-color: whitesmoke;
              color: #8d8f5d;
              font: 700 18px/1 RalewayMedium, monospace;
              text-shadow: 0 1px 1px rgb(236, 236, 236, 1);
              text-align: center;
              right: -25px;
              top: 30px;
              transform: rotate(-45deg);
              box-shadow: 0 2px 5px 0 rgba(66, 66, 38, 0.9);
              letter-spacing: 0.2vw;
            }
          }

          .package-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            flex-grow: 1;
            flex-basis: 0;
            gap: 5vw;
            padding: 0 0 0 3vw;

            .package-text {
              max-width: 80%;

              .package-element-largest {
                display: flex;
                flex-direction: row;
                font-size: 1.55vw;
                padding: 0 0 0.5vh 0;
                margin: 0 0 0 -2vw;
              }

              .package-element-small {
                display: flex;
                flex-direction: row;
                gap: 0.5vw;
                font-size: 1.1vw;
                align-items: center;
                padding: 0 0 0.5vh 0;
              }

              .package-element-large {
                display: flex;
                flex-direction: row;
                gap: 0.5vw;
                font-size: 1.4vw;
                align-items: center;
                align-self: center;
                justify-items: center;
                padding: 0.5vh 0 0.5vh 0;
                margin: 0 0 0 -2vw;
              }
            }

            .package-price-group {
              display: flex;
              flex-direction: column;
              padding: 0 3vw 0 2vw;
              gap: 1.5vh;
              max-width: 20%;

              .price-package {
                font-size: 2.5vw;
              }

              .price-session {
                display: flex;
                flex-direction: row;
                font-size: 1.1vw;
                gap: 0.3vw;
                align-items: center;
              }
            }

            i {
              font-size: 1.2vw;
              opacity: 0.5;
            }

            .circle-sketch-1, .circle-sketch-2, .circle-sketch-3, .circle-sketch-4 {
              position:relative;

              &::before {
                content: "";
                z-index: 1;
                border-width: 0.2vw;
                border-style: solid;
                border-color: whitesmoke;
                position: absolute;
                border-right-color: transparent;
                width: 100%;
                border-radius: 50%;
                height: 13vh;
              }

              &::after {
                content:"";
                z-index: 1;
                border-width: 0.2vw;
                border-style: solid;
                border-color: whitesmoke;
                border-left-color: transparent;
                border-top-color: transparent;
                position: absolute;
                width: 100%;
                border-radius: 50%;
                height: 11.5vh;
              }
            }

            .circle-sketch-1:before {
              left: 0;
              top: -1.5vh;
              transform: rotate(2deg);
            }

            .circle-sketch-1:after {
              left: 0;
              top: -1vh;
              transform: rotate(-1deg);
            }

            .circle-sketch-2:before {
              left: 0.05vw;
              top: -1.1vh;
              transform: rotate(-2deg);
            }

            .circle-sketch-2:after {
              left: -0.2vw;
              top: -1.38vh;
              transform: rotate(1deg);
            }

            .circle-sketch-3:before {
              left: -0.3vw;
              top: -2.05vh;
              transform: rotate(178deg);
            }

            .circle-sketch-3:after {
              left: 0;
              top: -0.25vh;
              transform: rotate(181deg);
            }


            .circle-sketch-4:before {
              left: -0.1vw;
              top: -2vh;
              transform: rotate(175deg);
            }

            .circle-sketch-4:after {
              left: 0.1vw;
              top: -0.8vh;
              transform: rotate(172deg);
            }

          }
        }
      }


    .subtitle {
      padding: 3vh 0 4vh 0;
      position: relative;

      font-size: 2.4vw;
      letter-spacing: 0.4vw;
      text-align: center;
      text-shadow: 0.15vw 0.15vw 0.15vw rgb(236, 236, 236, 0.9);

      &::after {
        position: absolute;
        content: "";
        width: 0%;
        height: 2px;
        background-color: #80824b;
        left: 50%;
        bottom: 1vh;
        transition: all 0.4s ease-in-out;
      }
    }

      .choose-express-lite {
        flex-grow: 1;


        .choose-express {
          background-color: #d9dac9;

          .package-element {
            display: flex;
            flex-direction: row;
            gap: 0.5vw;
            align-items: center;

            i {
              font-size: 1vw;
              opacity: 0.5;
            }
          }

          .package-price {
            font-size: 4vh;
            margin: 0 0 -1vh 0;
          }
        }

        .choose-lite {
          background-color: #c0c1a5;

          .package-element {
            display: flex;
            flex-direction: row;
            gap: 0.5vw;
            align-items: center;

            i {
              font-size: 1vw;
              opacity: 0.5;
            }
          }

          .package-price {
            font-size: 4vh;
            margin: 0 0 -1vh 0;
          }
        }
      }

      .choose-full-beyond {

        .choose-full {
          background-color: #a6a881;

          .package-element {
            display: flex;
            flex-direction: row;
            gap: 0.5vw;
            align-items: center;

            i {
              font-size: 1vw;
              opacity: 0.5;
            }
          }

          .package-price {
            font-size: 4vh;
            margin: 0 0 -1vh 0;
          }
        }

        .choose-beyond {
          background-color: #8d8f5d;

          .package-element {
            display: flex;
            flex-direction: row;
            gap: 0.5vw;
            align-items: center;

            i {
              font-size: 1vw;
              opacity: 0.5;
            }
          }

          .package-price {
            font-size: 4vh;
            margin: 0 0 -1vh 0;
          }
        }

      }

  }


    input[type='radio'] + label {
      margin: 0 0 0 0.5vw;
    }

    .challenge-level-fieldset {
      padding: 1vh 0 0 0;
    }


    fieldset {
      display: flex;
      flex-direction: row;
      justify-content: normal;
      border-radius: 5px 5px 5px 5px;
      line-height: 3.2vh;
      border: 1px solid #80824b;
      font-weight: bold;
      font-size: 1vw;
      background-color: white;

      .meeting-frequency {
        display: flex;
        flex-direction: column;
        padding: 0 14vw 0 5vw;
      }

      .meeting-location {
        display: flex;
        flex-direction: column;
      }

      .challenge-level {
        display: flex;
        flex-direction: row;
        gap: 7vw;
        padding: 0 0 0 5vw;
      }
    }
}
