.blog-post-page-mobile {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .blog-post-banner {
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      top: 99%;
      height: 10vh;
      width: 100%;

      background-image: linear-gradient(transparent, whitesmoke, whitesmoke);
    }

    .blog-post-photo-banner {
      width: 100%;
      height: 55vh;
      object-fit: cover;
    }

    .blog-post-title {
      padding: 1vh 0 8vh 0;
      margin: -46vh 0 10vh 0;

      color: whitesmoke;
      font-family: RalewayBold, monospace;
      font-size: 7vw;
      line-height: 10vh;
      text-align: center;
      text-transform: uppercase;

      text-shadow: 0 1px 0 #ccc,
      0 2px 0 #c9c9c9,
      0 3px 0 #bbb,
      0 6px 1px rgba(0,0,0,.1),
      0 0 5px rgba(0,0,0,.1),
      0 1px 3px rgba(0,0,0,.3),
      0 3px 5px rgba(0,0,0,.2);
    }

    .banner-bottom-line {
      display: flex;
      flex-direction: column;
      padding: -2vh 0 0 0;
      margin: -11vh 0 0 0;
      justify-content: space-between;
      gap: 4vh;

      color: whitesmoke;
      font-family: RalewayBold, monospace;
      font-size: 2.2vh;
      text-align: center;
      align-items: center;

      .post-details {
        display: flex;
        flex-direction: row;
        text-shadow: 3px 2px 0 rgba(0,0,0,0.2), -3px -2px 0 rgba(0,0,0,0.2);

        .blog-post-date {
          padding: 0 1.2vw 0 0;
        }

        .blog-topic {
          padding: 0 1.2vw 0 1.2vw;
        }

        .blog-reading-time {
          padding: 0 0 0 1.2vw;
        }
      }

      .blog-post-share-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1vw;
        margin: 0 4vw 0 0;
        z-index: 20;
        text-shadow: 4px 3px 0 rgba(0,0,0,0.2), -4px -3px 0 rgba(0,0,0,0.2);

        .share-icon {
          font-size: 2.5vh;

          &:hover {
            cursor: unset;
            color: whitesmoke;

          }
        }

        i {
          font-size: 5vh;
          transition: filter 0.2s ease-in-out, color 0.2s ease-in-out;
          text-shadow: 3px 2px 0 rgba(0,0,0,0.2), -3px -2px 0 rgba(0,0,0,0.2);

          &:hover {
            cursor: pointer;
            color: #d4b466;
            filter: drop-shadow(0 0 4vh #d4b466);
          }
        }
      }
    }
  }

  .blog-post-content {
    margin: 5vh 10vw 7vh 10vw;

    font-size: 1.4vh;
    font-weight: bold;
    text-align: justify;

    .highlighted-sketch, .highlighted-sketch-reverse {
      position: relative;
      z-index: 1;

      white-space: nowrap;
    }

    .highlighted-sketch:before, .highlighted-sketch-reverse:before {
      position:absolute;
      content: "";
      left: 0;
      top: 0;
      width:100%;
      height:1em;
      z-index: -1;

      border-width: 2px;
      border-style: solid;
      border-color: #d4b466;
      border-right-color: transparent;
      opacity: 0.7;
      border-radius: 0.25em;
    }

    .highlighted-sketch:after, .highlighted-sketch-reverse:after {
      position: absolute;
      content:"";
      left:0;
      top:0;
      width:100%;
      height:1em;
      z-index: -1;

      border-width: 2px;
      border-style: solid;
      border-color: #d4b466;
      border-left-color: transparent;
      border-top-color: transparent;
      opacity: 0.7;
      border-radius: 0.25em;
    }

    .highlighted-sketch:before {
      transform: rotate(2deg);
    }

    .highlighted-sketch-reverse:before {
      transform: rotate(-1deg);
    }

    .highlighted-sketch:after {
      transform: rotate(-1deg);
    }

    .highlighted-sketch-reverse:after {
      transform: rotate(1deg);
    }

    .blog-post-introduction {
      display: flex;
      flex-direction: column;
      line-height: 2.3vh;
      margin: 5vh 0 1vh 0;
      gap: 1.5vh;
      font-size: 1.5vh;
      text-shadow: 0.1vh 0.1vh 0.2vh rgb(164, 154, 105, 0.9);
    }

    .blog-post-paragraphs {
      display: flex;
      flex-direction: column;
      gap: 0.3vh;
      line-height: 2vh;

      .blog-paragraph-text-image {
        display: flex;
        flex-direction: column;

        .blog-paragraph-image-right {
          max-width: 100%;
          object-fit: contain;
        }

        .blog-paragraph-text {
          display: flex;
          flex-direction: column;
          max-width: 100%;
        }
      }

      .blog-heading {
        display: flex;
        flex-direction: column;

        .blog-heading-text {
          margin: 0 0 0 -3.5vw;
          padding: 3vh 0 2vh 0;
          z-index: 2;

          font-family: Kranky, monospace;
          font-size: 3.3vh;
          font-weight: lighter;
          text-transform: uppercase;
          line-height: 3.2vh;
        }

      }

      .blog-subtitle {
        display: flex;
        flex-direction: row;

        .blog-subtitle-numbered {
          position: absolute;
          margin: -2.5vh 0 0 -3vw;
          padding: 4vh 0 3vh 0;

          color: #d4b466;
          font-family: Sugiyama, monospace;
          font-size: 17vh;
          font-weight: bolder;
          opacity: 0.3;
        }

        .blog-subtitle-bullet {
          position: absolute;
          margin: -2vh 0 0 -4vw;
          padding: 4vh 0 3vh 0;

          color: #d4b466;
          font-size: 6vh;
          font-weight: bolder;
          opacity: 0.4;
        }

        .blog-subtitle-text {
          margin: 0 0 0 -1vw;
          padding: 4vh 0 2vh 2vw;
          z-index: 2;

          font-family: DawningNewDay, monospace;
          font-size: 3.5vh;
          font-weight: lighter;
          line-height: 3vh;
          text-align: left;
        }
      }

      .blog-bullet-point {
        margin: 0.2vh 7vw 0.2vh 7vw;
        font-style: italic;
        line-height: 1.9vh;
      }

      .blog-photo-paragraph {
        display: flex;
        flex-direction: column;

        .image-bordered {
          max-width: 100%;
          align-self: center;
        }

        .image-fade-in {
          max-width: 100%;
          align-self: center;
        }
      }

      .important-sentence {
        padding: 1vh 0 1vh 0;
        font-family: RalewayMedium, monospace;
        font-size: 1.5vh;
        letter-spacing: 0.15vw;
        line-height: 2.2vh;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0.2vh 0.2vh 0.2vh rgb(164, 154, 105, 0.9);
        font-weight: normal;
      }
    }

    .photo-credit {
      margin: 3vh 0 -3vh -5vw;
      text-shadow: 0.1vh 0.1vh 0.2vh rgb(164, 154, 105, 0.9);
      opacity: 0.5;
      font-size: 1.2vh;

      a {
        font-size: 1.2vh;
        text-shadow: 0.1vh 0.1vh 0.2vh rgb(164, 154, 105, 0.9);
      }
    }
  }
}