

.button-start {
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-size: 4vw;
    text-decoration: none;
    text-shadow: 0.15vw 0.15vw 0.15vw rgb(164, 154, 105, 0.9);
    transition: 0.4s ease-in-out;

    &:hover {
      cursor: pointer;
      color: #d8bc65;
      text-shadow: 0.15vw 0.15vw 0.15vw rgb(212, 180, 102, 0.9);
    }

    &:focus {
      text-decoration: none;
    }

    &:active {
      color: white;
    }
  }
}

.start-here-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13vw;
  height: 13vw;
  position: relative;

  &:after {
    transition: 0.4s ease-in-out;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 3px;
    border: 3px solid #80824b;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 2px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: white;
    z-index:-1;
  }

  &:hover {

    &:after {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:active {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);

    &:before {
      background-color: #8d8f5d;
    }

    &:after {
      border-color: #8d8f5d;
    }
  }
}