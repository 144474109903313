
.navbar {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  padding: 0.5vh 1vw 0.2vh 3vw;
  top: 0;
  width: 96vw;

  backdrop-filter: blur(2px);
  background-color: rgba(245, 245, 245, 0.8);
  box-shadow: 2px 5px 5px 0 rgba(128, 130, 75, 0.5);
  font-weight: bolder;
  user-select: none;
  z-index: 100;

  transition: 0.5s;

  &.small {
    padding: 0.5vh 1vw 0.5vh 3vw;

    .navbar-logo {

      img {
        margin: 0 0 0 1.5vw;
        height: 5vh;
      }
    }
  }

  .navbar-logo {
    display: flex;
    text-align: right;

    img {
      margin: 0 0 0 0;
      height: 8vh;

      transition: 0.5s;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .navbar-links {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4vw;
    text-align: right;

    color: #80824b;
    font-size: 1.5vw;
    text-decoration: none;
  }

  .navbar-languages {
    display: flex;
    flex-direction: row;
    gap: 0.5vw;

    img {
      height: 2.5vh;

      filter: blur(0px);
      cursor: pointer;
      opacity: 1;
      transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;

      &.disabled {
        filter: blur(1px);
        opacity: 0.5;
      }
    }
  }
}
