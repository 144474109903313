
.testimonial-card {
  position: relative;
  width: 32vw;
  height: 50vh;
  perspective: 1000px;

  cursor: pointer;
  filter: drop-shadow(0 0 0.5vh #80824b);
  font-weight: bold;

  &:hover {
    .front-card {
      transform: rotateY(180deg);
    }

    .back-card {
      transform: rotateY(0deg);
    }
  }

  &:nth-child(even):hover {
    .front-card {
      transform: rotateY(-180deg);
    }

    .back-card {
      transform: rotateY(180deg);
    }
  }

  &:nth-child(even):hover {
    .back-card {
      transform: rotateY(0deg);
    }
  }

  .front-card, .back-card {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: calc(100% - 8vh);
    width: calc(100% - 8vw);
    padding: 2vh 2vw 2vh 2vw;
    margin: 2vh 2vw 2vh 2vw;

    overflow: hidden;
    backface-visibility: hidden;
    border-radius: 2px;

    transform-style: preserve-3d;
    transition: ease-in-out 600ms;
  }

  .front-card {
    background-color: whitesmoke;

    .background {
      position: absolute;
      height: 100%;
      width: 100%;

      background-size: cover;
      opacity: 0.8;
      z-index: 1;
    }

    .front-text {
      opacity: 1;

      color: whitesmoke;
      text-shadow: 0.1vw 0.1vw 0.2vw rgb(128, 130, 75);
      z-index: 2;

      .name {
        padding: 7vh 0 2vh 0;
        font-size: 3.9vw;
      }

      .location {
        padding: 5vh 0 0 0;
        font-size: 2vw;
      }
    }
   }

  .back-card {
    transform: rotateY(-180deg);

    color: whitesmoke;
    font-size: 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;
  }
}
