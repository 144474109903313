.work-with-me-page {
  .work-with-me-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .work-with-me-wizard {
      width: 78vw;
      margin: 0 0 0 22vw;
    }
  }
}
