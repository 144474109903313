.testimonials-preview-mobile {
  position: absolute;
  text-align: center;
  font-size: 2vh;

  transition: 0.4s;
  transform: translateX(-50%);

  &.left {
    top: 5vh;
    left: calc(50% - 27vw);

    filter: blur(2px);
    transform: translateX(-50%) scale(0.5);
    opacity: 0.4;
  }

  &.middle {
    left: 50%;
    top: 2vh;
    z-index: 1;
  }

  &.right {
    top: 5vh;
    left: calc(50% + 27vw);

    filter: blur(2px);
    transform: translateX(-50%) scale(0.5);
    opacity: 0.4;
  }

  &.hidden {
    left: 50%;
    top: 2vh;
    transform: translateX(-50%) scale(0.5);
    opacity: 0;
  }

  .top {
    position: relative;
    width: 73vw;
    padding: 5vh 2vw 5vh 2vw;
    text-align: center;

    background-color: whitesmoke;
    color: #80824b;
    filter: drop-shadow(0 0 0.5vh #b3b8a7);

    i {
      position: absolute;

      background-color: whitesmoke;
      color: #80824b;
      font-size: 3.5vh;

      &.fa-quote-left {
        left: 1.5vw;
        top: 1vh;
      }

      &.fa-quote-right {
        right: 1.5vw;
        bottom: 1vh;
      }
    }
  }

  .bottom {
    display: flex;
    position: relative;
    flex-direction: row;

    .photo-name-location {
      display: flex;
      position: absolute;
      flex-direction: row;
      height: 8vh;
      width: 57vw;
      top: -2vh;
      left: 7.5vw;

      background-color: whitesmoke;
      border: 1px solid #80824b;
      filter: drop-shadow(0 0 1vh #b3b8a7);

      .photo {
        height: 6vh;
        margin: 1vh 0 1vh 1vh;

        border: 1px solid #80824b;
        border-radius: 10px 10px 10px 10px;
      }

      .name-location {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        gap: 1vh;
        margin: 1vh 0.5vw 1vh 0;

        .name {
          font-weight: bolder;
        }

        .location {

        }
      }
    }

  }

}