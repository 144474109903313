.introduction {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 0 6vh 0;
  user-select: none;

  .introduction-text {
    max-width: 50%;
    padding: 3vh 0 0 0;

    .intro-title {
      font-size: 3vw;
      font-weight: normal;
      letter-spacing: 0.3vw;
      margin: 4vh 3vw 8vh 3vw;
      text-align: center;
      text-shadow: 0.3vw 0.3vw 0.2vw rgb(164, 154, 105,0.7);
    }

    .intro-description {
      margin: 0 7vw 0 7.5vw;
      font-size: 1.5vw;
      font-weight: bold;
      text-align: center;

      a {
        margin: 0.5vh 0 0.5vh 0;

        font-size: unset;
        text-shadow: unset;
      }

      div {
        margin: 0.5vh 0 0.5vh 0;
      }

      .intro-description-hover {
        display: flex;
        justify-content: center;
        flex-direction: row;

      .intro-description-hover-text {
        font-family: RalewayMedium, monospace;
        padding: 0 0.5vw 0 0.5vw;
        font-weight: normal;
        transition: color 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          color: #d4b466;
        }
      }
      }
    }
  }

  .intro-image {
    max-width: 35%;
    padding: 3vh 0 0 0;
  }
}
