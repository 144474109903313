.side-bar-content {
  position: fixed;
  padding: 0 2vw 5vh 2vw;
  width: 20vw;

  pointer-events: none;

  .side-bar-background-number {
    position: absolute;
    top: 2vh;
    left: 2vw;
    right: 5vw;

    font-size: 20vw;
    font-weight: bold;
    line-height: 30vh;
    opacity: 0.2;
    text-align: center;

    .step-counter {
      font-family: RalewayMedium, monospace;
    }
  }

  .side-bar-title {
    font-size: 1.2vw;
    font-weight: normal;
    letter-spacing: 0.3vw;
    text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
  }

  .side-bar-subtitle {
    font-size: 1.5vw;
    font-weight: bold;
    letter-spacing: 0.3vw;
    text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
  }

  .side-bar-steps {
    color: #80824B;
    padding: 2.5vh 0 0 2vw;
    font-size: 2vw;
    font-weight: normal;
    letter-spacing: 0.5vw;
    text-shadow: 0.15vw 0.15vw 0.15vw rgb(164, 154, 105, 0.9);

    i {
      padding: 0 0 0 3vw;
      font-size: 2vw;
    }

    .active {
      color: #80824b;
      font-weight: bolder;
    }
  }
}
