.step-one-content {
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  user-select: none;

  background-color: whitesmoke;
  color: #80824b;

  .step-one-title {
    padding: 0 0 3vh 0;
    margin: 5vh 0 0 0;
    font-size: 3.5vw;
    font-weight: normal;
    letter-spacing: 0.4vw;
    text-align: center;
    text-shadow: 0.15vw 0.15vw 0.15vw rgb(164, 154, 105, 0.9);

  }

  .choose-action-plan {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    button {
      width: 9vw;

      background-color: whitesmoke;
      border: 1px solid #80824b;
      color: #80824b;

      transition: 0.5s;

      &:hover {
        background-color: #80824b;
        border: 1px solid whitesmoke;
        color: whitesmoke;
      }
    }

    .choose-single-plan {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22vw;

      overflow: hidden;

      img {
        width: 100%;

        cursor: pointer;
        border: 2px solid whitesmoke;
        filter: grayscale(70%) blur(1px);

        transition: all 0.5s ease-in-out;
      }

      .choose-plan-name {
        position: absolute;

        color: white;
        font-family: AngellaWhite, RalewayMedium, monospace;
        font-size: 5vw;
        opacity: 0;
        pointer-events: none;
        text-shadow: 0.1vw 0.1vw 0.3vw rgba(255, 255, 255, 0);

        transition: all 0.5s ease-in-out;
      }

      &:hover {
        div {
          opacity: 1;
          text-shadow: 0.1vw 0.1vw 0.3vw rgba(236, 236, 236, 1);
        }

        img {
          cursor: pointer;
          border: 2px solid #80824b;
          filter: grayscale(0%) blur(0px);
        }
      }
    }

    .choose-action-plan-intro {
      display: flex;
      flex-direction: column;
      padding: 0 0 3vh 0;

      font-size: 1.5vw;
      font-weight: bold;
      text-align: center;
    }

    .choose-action-plan-options {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1vw;

      .choose-resilience {
        display: flex;
        flex-direction: column;
      }

      .choose-head-ahead {
        display: flex;
        flex-direction: column;
      }

      .choose-flow {
        display: flex;
        flex-direction: column;
      }
    }

    .choose-action-plan-outro {
      display: flex;
      flex-direction: column;
      padding: 5vh 8vw 5vh 8vw;
      gap: 2vh;
      line-height: 3vh;

      font-size: 1.2vw;
      font-weight: bold;
      text-align: center;
    }
  }
}
