.end-here-button-mobile {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #e6e6db;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 1;

  &.animating {
    opacity: 1;

    .progress-bar {
      max-width: 0;
    }
  }

  .thank-you-mobile {
    font-family: AngellaWhite, RalewayMedium, monospace;
    font-size: 12vh;

  }

  .get-in-touch-mobile {
    font-family: AngellaWhite, RalewayMedium, monospace;
    font-size: 4vh;
  }

  .progress-bar {
    height: 2px;
    width: 100%;
    max-width: 80%;

    background-color: whitesmoke;
    transition: all 4s ease-in-out;
  }
}
