.start-here-page-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .start-here-mobile-text {

    .start-here-text-mobile-title {
      text-align: center;
      font-size: 3vh;
      font-weight: normal;
      letter-spacing: 0.3vw;
      text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      padding: 10vh 0 7vh 0;
    }

    .start-here-text-mobile-subtitle {
      text-align: center;
      font-size: 4vh;
      font-weight: bold;
      letter-spacing: 0.3vw;
      text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      padding: 0 0 7vh 0;
    }
  }

  i {
    font-size: 3vh;
    margin: 9vh 0 11vh 0;
  }
}