.blog-post-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .blog-post-banner {
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      top: 99%;
      height: 10vh;
      width: 100%;

      background-image: linear-gradient(transparent, whitesmoke, whitesmoke);
    }

    .blog-post-photo-banner {
      width: 100%;
      height: 55vh;
      object-fit: cover;
    }

    .blog-post-title {
      padding: 3vh 15vw 8vh 15vw;
      margin: -46vh 0 10vh 0;

      color: whitesmoke;
      font-family: RalewayBold, monospace;
      font-size: 5vw;
      line-height: 13vh;
      text-align: center;
      text-transform: uppercase;

      text-shadow: 0 1px 0 #ccc,
      0 2px 0 #c9c9c9,
      0 3px 0 #bbb,
      0 4px 0 #b9b9b9,
      0 5px 0 #aaa,
      0 6px 1px rgba(0,0,0,.1),
      0 0 5px rgba(0,0,0,.1),
      0 1px 3px rgba(0,0,0,.3),
      0 3px 5px rgba(0,0,0,.2),
      0 5px 10px rgba(0,0,0,.25),
      0 10px 10px rgba(0,0,0,.2),
      0 20px 20px rgba(0,0,0,.15);
    }

    .banner-bottom-line {
      display: flex;
      flex-direction: row;
      padding: 0 0 0 3vw;
      margin: -11vh 0 0 0;
      justify-content: space-between;

      color: whitesmoke;
      font-family: RalewayBold, monospace;
      font-size: 2vw;

      .post-details {
        display: flex;
        flex-direction: row;
        text-shadow: 3px 2px 0 rgba(0,0,0,0.2), -3px -2px 0 rgba(0,0,0,0.2);

        .blog-post-date {
          padding: 0 1.2vw 0 0;
        }

        .blog-topic {
          padding: 0 1.2vw 0 1.2vw;
        }

        .blog-reading-time {
          padding: 0 0 0 1.2vw;
        }
      }

      .blog-post-share-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1vw;
        margin: 0 4vw 0 0;
        z-index: 20;
        text-shadow: 4px 3px 0 rgba(0,0,0,0.2), -4px -3px 0 rgba(0,0,0,0.2);

        .share-icon {
          font-size: 3vh;

          &:hover {
            cursor: unset;
            color: whitesmoke;

          }
        }

        i {
          font-size: 5vh;
          transition: 0.2s ease-in-out;

          &:hover {
            cursor: pointer;
            color: #d4b466;
            filter: drop-shadow(0 0 4vh #d4b466);
          }
        }
      }
    }
  }

  .blog-post-content {
    margin: 10vh 17vw 10vh 17vw;

    font-size: 1.3vw;
    font-weight: bold;
    line-height: 2.5vh;
    text-align: justify;

    .highlighted-sketch, .highlighted-sketch-reverse {
      position: relative;
      z-index: 1;

      white-space: nowrap;
    }

    .highlighted-sketch:before, .highlighted-sketch-reverse:before {
      position:absolute;
      content: "";
      left: 0;
      top: 0;
      width:100%;
      height:1em;
      z-index: -1;

      border-width: 2px;
      border-style: solid;
      border-color: #d4b466;
      border-right-color: transparent;
      opacity: 0.7;
      border-radius: 0.25em;
    }

    .highlighted-sketch:after, .highlighted-sketch-reverse:after {
      position: absolute;
      content:"";
      left:0;
      top:0;
      width:100%;
      height:1em;
      z-index: -1;

      border-width: 2px;
      border-style: solid;
      border-color: #d4b466;
      border-left-color: transparent;
      border-top-color: transparent;
      opacity: 0.7;
      border-radius: 0.25em;
    }

    .highlighted-sketch:before {
      transform: rotate(2deg);
    }

    .highlighted-sketch-reverse:before {
      transform: rotate(-1deg);
    }

    .highlighted-sketch:after {
      transform: rotate(-1deg);
    }

    .highlighted-sketch-reverse:after {
      transform: rotate(1deg);
    }

    .blog-post-introduction {
      display: flex;
      flex-direction: column;
      line-height: 4vh;
      margin: 0 0 3.5vh 0;
      gap: 1.5vh;
      font-size: 1.5vw;
      text-shadow: 0.1vw 0.1vw 0.2vw rgb(164, 154, 105, 0.9);
    }

    .blog-post-paragraphs {
      display: flex;
      flex-direction: column;
      gap: 1.2vh;
      line-height: 3vh;

      .blog-paragraph-text-image {
        display: flex;
        flex-direction: row;

        .blog-paragraph-image-right {
          max-width: 45%;
          object-fit: contain;
        }

        .blog-paragraph-text {
          display: flex;
          flex-direction: column;
          max-width: 55%;
        }
      }

      .blog-heading {
        display: flex;
        flex-direction: column;

        .blog-heading-text {
          margin: 0 0 0 -3.5vw;
          padding: 9vh 0 4vh 0;
          z-index: 2;

          font-family: Kranky, monospace;
          font-size: 4vw;
          font-weight: lighter;
          text-transform: uppercase;
        }

      }

      .blog-subtitle {
        display: flex;
        flex-direction: row;


        .blog-subtitle-numbered {
          position: absolute;
          margin: -2.5vh 0 0 -3vw;
          padding: 5vh 0 3vh 0;

          color: #d4b466;
          font-family: Sugiyama, monospace;
          font-size: 20vw;
          font-weight: bolder;
          opacity: 0.3;
        }

        .blog-subtitle-bullet {
          position: absolute;
          margin: -2vh 0 0 -4vw;
          padding: 5vh 0 3vh 0;

          color: #d4b466;
          font-size: 8vw;
          font-weight: bolder;
          opacity: 0.3;
        }

        .blog-subtitle-text {
          margin: 0 0 0 -1vw;
          padding: 7vh 0 6vh 0;
          z-index: 2;

          font-family: DawningNewDay, monospace;
          font-size: 4.5vw;
          font-weight: lighter;
        }
      }

      .blog-bullet-point {
        margin: 0.5vh 2vw 1vh 4vw;
        font-style: italic;
        line-height: 3.5vh;
      }

      .blog-photo-paragraph {
        display: flex;
        flex-direction: column;

        .image-bordered {
          max-width: 70%;
          align-self: center;
          padding: 0 0 2vh 0;
        }

        .image-fade-in {
          max-width: 70%;
          align-self: center;
        }
      }

      .important-sentence {
        padding: 2vh 0 2vh 0;
        font-family: RalewayDots, monospace;
        font-size: 1.5vw;
        letter-spacing: 0.2vw;
        line-height: 4vh;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
        font-weight: bold;
      }
    }

    .photo-credit {
      margin: 5vh 0 -5vh -14vw;
      font-size: 2vh;
      text-shadow: 0.1vw 0.1vw 0.2vw rgb(164, 154, 105, 0.9);

      a {
        font-size: 2vh;
        text-shadow: 0.1vw 0.1vw 0.2vw rgb(164, 154, 105, 0.9);
      }
    }
  }
}