
.landing-page {
  display: inline-block;
  position: relative;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: whitesmoke;
  user-select: none;

  &.mobile {
    .text-content{
      .buttons-content {
        button {
          font-size: 7vw;
        }
      }

      .title {
        margin: 40vh 0 15vh 0;
        font-size: 12vw;
      }
    }
  }

  .background-landscape {
    display: block;
    position: relative;
    height: 100vh;
    width: 100vw;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(0%);
    opacity: 1;

    transition: all 1.5s ease-in-out;

    &.end-position {
      filter: grayscale(80%);
    }

    &.unloading {
      opacity: 0;
    }
  }

  .text-content {
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    width: 100vw;
    z-index: 1;

    overflow: hidden;

    .buttons-content {

      button {
        height: unset;
        padding: 1vh 2.5vw 1vh 2.5vw;

        background-color: transparent;
        border: solid 1px whitesmoke;
        border-radius: 0;
        color: whitesmoke;
        cursor: pointer;
        font-family: RalewayThin, monospace;
        font-size: 2vw;
        font-weight: bold;
        letter-spacing: 0.1vw;
        opacity: 0;

        transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;

        &:hover {
          background-color: whitesmoke;
          color: rebeccapurple;
          font-weight: bolder;
        }

        &:first-child {
          margin: 1vh 3vw 1vh 1vw;
          transform: translate(-15vw, 0);
        }

        &:last-child {
          margin: 1vh 1vw 1vh 3vw;
          transform: translate(15vw, 0);
        }

        &.end-position {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
    }


    .title {
      display: flex;
      margin: 45vh 0 1.5vh 0;
      align-items: center;

      opacity: 0;
      transform: translate(0, 15vh);
      user-select: none;

      transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;

      img {
        height: 20vh;
        width: auto;
      }

      &.end-position {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }
}
