.end-here-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  .thank-you {
    font-family: AngellaWhite, RalewayMedium, monospace;
    font-size: 15vw;
  }

  .get-in-touch {
    font-family: AngellaWhite, RalewayMedium, monospace;
    font-size: 5vw;
  }

  .progress-bar {
    height: 4px;
    width: 100%;
    max-width: 80%;

    background-color: #e6e6db;
    transition: all 4s ease-in-out;

    &.animating {
      max-width: 0;
    }
  }
}
