.footer-mobile {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 45vh;
  justify-content: space-between;
  padding: 3vh 2vw 3vh 2vw;

  background-color: #80824b;
  color: whitesmoke;
  font-weight: bold;
  user-select: none;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;

    font-size: 1.9vh;

    .contact-me {
      display: flex;
      flex-direction: column;
      justify-content: center;

      input, textarea {
        border: 1px solid #d4b466;
        background-color: whitesmoke;

        &:focus-visible {
          outline-color: #d4b466;
        }

        &::placeholder {
          color: #80824b;
          font-weight: normal;
        }
      }

      .thank-you {
        font-family: AngellaWhite, RalewayMedium, monospace;
        font-size: 5vh;
      }

      .contact-name-email {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .contact-name {
          padding: 1vh 0 0.5vh 1vw;

          input {
            border-radius: 10px 5px 5px 5px;
            padding: 0.7vh 1vw 0.7vh 1vw;
            width: 30vw;
            font-size: 1.4vh;
          }
        }

        .contact-email {
          padding: 1vh 1vw 0.5vh 1vw;

          input {
            border-radius: 5px 10px 5px 5px;
            padding: 0.7vh 1vw 0.7vh 1vw;
            width: 50vw;
            font-size: 1.4vh;
          }
        }
      }

      .contact-text-area {
        padding: 0 1vw 1vh 1vw;

        textarea {
          border-radius: 5px 5px 10px 10px;
          padding: 1vh 1vw 1vh 1vw;
          height: 10vh;
          width: 84vw;
          font-size: 1.4vh;
        }
      }

      .contact-send-button {
        align-self: center;

        button {
          margin: 0 0 3vh 0;

          font-size: 2vh;
          background-color: #80824b;
          border: 1px solid whitesmoke;
          color: whitesmoke;
          width: 25vw;
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;

    .location {
      display: flex;
      flex-direction: row;
      font-size: 2vh;
      gap: 1vw;
    }

    .social-media {
      display: flex;
      flex-direction: row;
      gap: 2vw;

      i {
        font-size: 8vw;
      }
    }

    .prints-copyright {
      display: flex;
      flex-direction: row;
      align-self: stretch;
      justify-content: space-between;
      padding: 1vh 0 3vh 0;

      .small-prints {
        display: flex;
        flex-direction: column;
        gap: 1vh;

        div {
          font-size: 2vh;
          font-weight: lighter;
        }
      }

      .copyright {
        padding: 3.2vh 0 0 0;
        font-weight: lighter;
      }

    }
  }
}
