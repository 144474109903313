.page-indicator {
  width: 0.7vw;
  height: 0.7vw;

  background: transparent;
  border: 1px solid #80824b;
  border-radius: 2px 2px 2px 2px;
  cursor: pointer;

  transition: 0.2s;

  &.desktop {
    &:hover {
      background: #d4b466;
      border: 1px solid transparent;
    }
  }

  &.active {
    background: #80824b;
    border: 1px solid transparent;
  }
}