
.testimonials-preview {
  position: absolute;
  text-align: center;
  font-size: 1.2vw;

  transition: 0.4s;
  transform: translateX(-50%);

  &.left {
    top: 4vh;
    left: calc(50% - 28vw);

    filter: blur(2px);
    transform: translateX(-50%) scale(0.6);
    opacity: 0.5;
  }

  &.middle {
    left: 50%;
    top: 2vh;
    z-index: 1;
  }

  &.right {
    top: 4vh;
    left: calc(50% + 28vw);

    filter: blur(2px);
    transform: translateX(-50%) scale(0.6);
    opacity: 0.5;
  }

  &.hidden {
    left: 50%;
    top: 2vh;
    transform: translateX(-50%) scale(0.6);
    opacity: 0;
  }

  .top {
    position: relative;
    width: 35vw;
    padding: 5vh 3vw 5vh 3vw;
    text-align: justify;
    text-align-last: center;

    background-color: #d8dbcf;
    color: white;
    font-weight: bolder;
    filter: drop-shadow(0 0 0.5vh #b3b8a7);

    i {
      position: absolute;

      background-color: #d8dbcf;
      color: #80824b;
      font-size: 2vw;


      &.fa-quote-left {
        left: 1vw;
        top: 1vh;
      }

      &.fa-quote-right {
        right: 1vw;
        bottom: 1vh;
      }

    }
  }

  .bottom {
    display: flex;
    position: relative;
    flex-direction: row;

    .photo-name-location {
      display: flex;
      position: absolute;
      flex-direction: row;
      height: 12.5vh;
      width: 18vw;
      top: -2vh;
      left: 11.5vw;

      background-color: whitesmoke;
      border: 1px solid #80824b;
      filter: drop-shadow(0 0 1vh #b3b8a7);

      .photo {
        height: 5.5vw;
        margin: 1.25vh 0 1.25vh 1.25vh;

        border: 1px solid #80824b;
        border-radius: 10px 10px 10px 10px;
      }

      .name-location {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        gap: 1vh;
        margin: 1.25vh 1vw 1.25vh 0;

        .name {
          font-weight: bolder;
        }

        .location {

        }
      }
    }

  }
}
