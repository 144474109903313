
.testimonials-fullpage {

  .testimonials-fullpage-content {
    display: flex;
    flex-grow: 1;

    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      top: 0;
     }
  }
}
