
.content-transitioner {

  > div, > form {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

    &.enter,
    &.exit {
      opacity: 0;
      transform: translateY(100vh);
    }

    &.enter-active,
    &.enter-done {
      opacity: 1;
      transform: translateY(0);
    }

    &.exit-active,
    &.exit-done {
      opacity: 0;
      transform: translateY(-100vh);
    }
  }
}
