.footer {
  display: flex;
  flex-shrink: 0;
  height: 33vh;
  justify-content: space-between;
  padding: 3vh 2vw 3vh 2vw;

  background-color: #80824b;
  color: whitesmoke;
  font-weight: bold;
  user-select: none;

  .left-side-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1vh;

    font-weight: lighter;

    .location {
      display: flex;
      flex-direction: column;
      padding: 5vh 0 0 0;
      font-weight: bold;
      gap: 1vh;
    }

    .small-prints {
      padding: 0 0 1vh 0;
      transition: font-weight 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        font-weight: bolder;
      }
    }
  }

  .middle-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vh;

    font-size: 1.5vw;

    .contact-me {
      display: flex;
      flex-direction: column;
      justify-content: center;

      input, textarea {
        border: 1px solid #d4b466;
        background-color: whitesmoke;
        font-weight: bolder;

        &:focus-visible {
          outline-color: #d4b466;
        }

        &:hover {
          cursor: text;
        }

        &::placeholder {
          color: #80824b;
          font-weight: normal;
        }
      }

      .thank-you {
        font-family: AngellaWhite, RalewayMedium, monospace;
        font-size: 5vh;
      }

      .contact-name-email {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .contact-name {
          padding: 1vh 0 0.5vh 1vw;

          input {
            border-radius: 10px 5px 5px 5px;
            padding: 0.7vh 1vw 0.7vh 1vw;
            width: 10.6vw;
          }
        }

        .contact-email {
          padding: 1vh 1vw 0.5vh 1vw;

          input {
            border-radius: 5px 10px 5px 5px;
            padding: 0.7vh 1vw 0.7vh 1vw;
            width: 20.6vw;
          }
        }
      }

      .contact-text-area {
        padding: 1vh 1vw 1vh 1vw;

        textarea {
          border-radius: 5px 5px 10px 10px;
          padding: 1vh 1vw 1vh 1vw;
          height: 10vh;
          width: 34.5vw;
        }
      }

      .contact-send-button {
        align-self: center;

        button {
          margin: 1vh 0 5vh 0;

          background-color: #80824b;
          border: 1px solid whitesmoke;
          color: whitesmoke;
          width: 6.5vw;

          transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

          &:hover {
            background-color: whitesmoke;
            color: #80824b;
          }
        }
      }
    }
  }

  .right-side-footer {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: space-between;

    .social-media {
      display: flex;
      flex-direction: row;
      gap: 1vw;
      padding: 5vh 0 0 0;

      i, a {
        font-size: 4vh;
        transition: filter 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
          cursor: pointer;

          color: #d4b466;
          filter: drop-shadow(0 0 10vh #d4b466);
        }
      }
    }

    .copyright {
      padding: 0 0.2vw 0 0;
      font-weight: lighter;
    }
  }
}
