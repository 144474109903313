.blog-card-portrait {
  position: relative;
  perspective: 1000px;

  cursor: pointer;

  color: whitesmoke;
  font-size: 1.1vw;
  font-weight: bold;
  text-shadow: none;

  &:hover {
    text-shadow: none;
  }

  >:not(.card-background) {
    pointer-events: none;
  }

  &.down {

    .card-length {
      position: absolute;
      top: 7.5vh;
      left: 1.5vw;
      bottom: unset;
      right: unset;
    }

    .card-topic {
      position: absolute;
      top: 4.5vh;
      left: 1.5vw;
      bottom: unset;
      right: unset;
    }

    .card-date {
      position: absolute;
      top: 1.5vh;
      left: 1.5vw;
      bottom: unset;
      right: unset;
    }

    .card-box {
      position: absolute;
      bottom: 3vh;

      .card-title {
        text-align: right;
      }

      .card-description {
        text-align: right;
      }
    }
  }

  .card-background {
    position: absolute;
    height: 100%;
    width: 100%;

    background-size: cover;
    border-radius: 2px;
    box-shadow: 3px 3px 8px #b3b8a7;
    opacity: 0.9;
    z-index: -1;

    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .card-length {
    position: absolute;
    bottom: 7.5vh;
    right: 1.5vw;

    color: white;
    filter: drop-shadow(1px 2px 1px whitesmoke);
    font-family: RalewayBold, monospace;
    user-select: none;
    text-align: right;
  }

  .card-topic {
    position: absolute;
    bottom: 4.5vh;
    right: 1.5vw;

    color: white;
    filter: drop-shadow(1px 2px 1px whitesmoke);
    font-family: RalewayBold, monospace;
    user-select: none;
    text-align: right;
  }

  .circle-sketch {
    position:relative;
  }

  .circle-sketch:before {
     content: "";
     z-index: -1;
     left: -0.5em;
     top: -0.1em;
     border-width: 2px;
     border-style: solid;
     border-color: whitesmoke;
     position: absolute;
     border-right-color: transparent;
     width: 100%;
     height: 1em;
     transform: rotate(2deg);
     opacity: 0.7;
     border-radius: 50%;
     padding: 0.1em 0.25em;
   }

  .circle-sketch:after {
    content:"";
    z-index: -1;
    left: -0.5em;
    top: 0.1em;
    padding: 0.1em 0.25em;
    border-width: 2px;
    border-style: solid;
    border-color: whitesmoke;
    border-left-color: transparent;
    border-top-color: transparent;
    position: absolute;
    width: 100%;
    height: 1em;
    transform: rotate(-1deg);
    opacity: 0.7;
    border-radius: 50%;
  }

  .card-date {
    position: absolute;
    bottom: 1.5vh;
    right: 1.5vw;

    color: white;
    filter: drop-shadow(1px 2px 1px whitesmoke);
    font-family: RalewayBold, monospace;
    user-select: none;
    text-align: right;
  }

  .card-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100.15%;
    height: 15vw;
    margin: 3vh 0 0 0;

    backdrop-filter: blur(6px) brightness(0.8);
    user-select: none;

    .mobile-shading {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: black;
      opacity: 0;
    }

    .card-title {
      padding: 1.5vh 1vw 0 1vw;
      z-index: 1;

      color: whitesmoke;
      font-family: RalewayExtraBold, monospace;
      font-size: 1.6vw;
      text-align: left;
      text-transform: uppercase;
    }

    .card-description {
      padding: 0 1vw 1.5vh 1vw;
      z-index: 1;

      color: white;
      font-size: 1vw;
      text-align: left;
    }
  }
}
