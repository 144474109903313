
.terms-and-conditions-page-mobile {

  .terms-page-content-mobile {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 5vh 10vw 5vh 10vw;

    font-weight: bold;

    .main-title {
      padding: 2vh 0 2vh 0;
      font-size: 3vh;
      font-weight: normal;
      text-align: center;
      text-shadow: 0.2vh 0.2vh 0.2vh rgb(164, 154, 105, 0.9);
      text-transform: uppercase;
    }

    .last-update {
      padding: 0 0 1vh 0;
      font-size: 1.1vh;
      font-style: italic;
      text-align: right;
    }

    .subtitle {
      padding: 2vh 0 1vh 0;
      font-size: 1.5vh;
      line-height: 2.5vh;
      letter-spacing: 0.3vw;
      text-shadow: 0.2vh 0.2vh 0.2vh rgb(164, 154, 105, 0.9);
      text-transform: uppercase;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 1vh;

      font-size: 1.3vh;
      font-weight: bold;
      text-align: justify;
    }

  }
}
