.introduction-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 5vh 0;
  text-align: center;
  gap: 3vh;

  color: #80824b;
  font-weight: bolder;
  user-select: none;

  .image-wrapper {
    position: relative;

    .intro-image {
      position: relative;
      width: 100%;
      margin: -3vh 0 0 0;
    }

    .over-image-text {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      bottom: 2vh;
      gap: 1.5vh;

      .intro-title {
        background-color: whitesmoke;
        padding: 2vh 2vw 2vh 2vw;
        top: 32vh;
        width: 85%;

        font-size: 2.8vh;
        opacity: 0.8;
        text-shadow: 0.2vw 0.2vw 0.4vw rgb(75, 78, 49, 1);
      }

      .intro-description {
        background-color: whitesmoke;
        padding: 2vh 2vw 2vh 2vw;
        top: 47vh;
        width: 85%;

        font-size: 2vh;
        font-weight: bold;
        opacity: 0.9;
      }

      .intro-description-bold {
        display: flex;
        justify-content: center;
        flex-direction: row;

        .intro-description-bold-text {
          padding: 0 1.2vw 0 1.2vw;

          text-decoration: underline;
        }
      }
    }
  }

  i {
    font-size: 3vh;
    margin: -2vh 0 0 0;
  }
}
