.step-one-content-mobile {
  display: flex;
  flex-direction: column;
  user-select: none;

  background-color: whitesmoke;
  color: #80824b;

  .step-one-title-mobile {
    font-size: 2.7vh;
    font-weight: bold;
    letter-spacing: 0.4vw;
    text-align: center;
    text-shadow: 0.5vw 0.5vw 0.5vw rgb(164, 154, 105, 0.9);
    padding: 2vh 0 2vh 0;
  }

  .choose-action-plan-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;

    .choose-action-plan-intro-mobile {
      font-size: 2vh;
      font-weight: bold;
      text-align: center;
      padding: 1vh 5vw 0 5vw;
    }

    .choose-action-plan-options-mobile {
      display: flex;
      flex-direction: column;
      gap: 3vh;

      .plan-option-section-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vh;

        &.selection-made {
          img {
            filter: grayscale(70%) blur(1px);
          }

          .choose-text-mobile {
            filter: grayscale(70%) blur(1px);
          }
        }


        &.selected {
          img {
            outline: 2px solid whitesmoke;
            filter: grayscale(0%) blur(0px);
          }

          .choose-text-mobile {
            filter: grayscale(0%) blur(0px);
          }
        }


        label {
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: all 0.5s ease-in-out;

          img {
            width: 70%;
          }
        }

        button {
          align-self: center;
          font-size: 2vh;
          margin: 0 0 0 0;
          padding: 0 3vw 0 3vw;
          text-align: center;

          background-color: whitesmoke;
          border: 1px solid #80824b;
          color: #80824b;
        }

        .choose-text-mobile {
          position: absolute;
          text-align: center;

          color: white;
          font-family: AngellaWhite, RalewayMedium, monospace;
          font-size: 8vh;
          pointer-events: none;
          text-shadow: 2vw 2vw 0.5vw rgba(255, 255, 255, 0);
          z-index: 1;
        }
      }
    }

    .choose-action-plan-outro-mobile {
      font-size: 2vh;
      font-weight: bold;
      text-align: justify;
      text-align-last: center;
      padding: 2vh 5vw 2vh 5vw;
      gap: 5vh;

    }
  }
}
