
.coming-soon-page {
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  padding: 1vh 1vw 1vh 1vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: whitesmoke;
  color: #a49a69;
  user-select: none;

  .align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .body {
    display: flex;
    font-family: RalewayDots, monospace;
    font-size: 1.5vw;
    font-weight: bolder;
    text-align: center;
  }

  .coming-soon {
    display: flex;
    font-family: RalewayThin, monospace;
    font-stretch: ultra-expanded;
    font-size: 5.5vw;
    font-weight: lighter;
    padding: 10vh 0 8vh 0;
    text-align: center;
    text-shadow: 0.3vw 0.3vw 0.3vw rgb(164, 154, 105,0.7);
  }

  .paragraph-space {
    padding: 2vh 0 2vh 0;
  }

  .subtitle {
    color: #a49a69;
    display: flex;
    font-family: RalewayDots, monospace;
    font-size: 1.6vw;
    padding: 0 0 0 0;
    font-weight: bolder;
    position: relative;
    text-align: center;
    z-index: 1;
  }

  .text-content {
    flex: 3 0 0;
    max-width: 60%;
    position: relative;

    .banner {
      position: relative;
    }

    .title {
      color: #a49a69;
      display: flex;
      font-family: Megrim, monospace;
      font-size: 8vw;
      font-weight: lighter;
      padding: 1vh 0 2vh 0;
      text-align: center;
      text-shadow: 0 0 0.6rem rgb(164, 154, 105, 0.9);
      z-index: 1;
    }
  }

  .image-content {
    flex: 2 0 auto;
    max-width: 40%;
  }
}
