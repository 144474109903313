.about-coaching {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 5vw 5vh 5vw;

  .about-coaching-content {
    display: flex;
    flex-direction: row;
    font-weight: bold;

    .about-coaching-image {
      max-width: 40%;
    }

    .introduction {
      display: flex;
      flex-direction: column;
      max-width: 60%;
      font-size: 1.3vw;
      margin: 0 0 0 3vw;
      gap: 2vh;

      text-align: justify;
    }
  }

  .coaching-expectations {
    margin: 5vh 12vw 0 12vw;

    .title {
    margin: 0 0 3vh 0;

    font-size: 2.5vw;
    font-weight: bold;
    letter-spacing: 0.3vw;
    text-align: center;
    text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
  }

    .subtitle {
    margin: 0 0 1.5vh 0;

    font-size: 2.1vw;
    font-weight: normal;
    letter-spacing: 0.3vw;
    text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
  }

    .text {
    margin: 0 0 2.2vh 3vw;

    font-size: 1.3vw;
    font-weight: bold;
    text-align: justify;
    }
  }
}