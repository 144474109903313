
.homepage {
  .page-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    background: whitesmoke;
  }
}
