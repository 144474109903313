.privacy-statement-page {
  .privacy-page-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 5vh 10vw 10vh 10vw;

    font-weight: bold;

    .main-title {
      padding: 2vh 0 3vh 0;
      font-size: 4vw;
      font-weight: normal;
      letter-spacing: 0.3vw;
      text-align: center;
      text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      text-transform: uppercase;
    }

    .last-update {
      padding: 0 0 2vh 0;
      font-size: 1.3vw;
      font-style: italic;
      text-align: right;
    }

    .subtitle {
      padding: 4vh 0 2vh 0;
      font-size: 1.8vw;
      font-weight: normal;
      letter-spacing: 0.3vw;
      text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      text-transform: uppercase;
    }

    .data-title {
      padding: 2vh 0 2vh 0;
      font-size: 1.5vw;
      font-weight: normal;
      letter-spacing: 0.3vw;
      text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 2vh;

      font-size: 1.3vw;
      font-weight: bold;
      text-align: justify;
    }
  }
}
