
.about-page-mobile {
  .about-mobile-content {
    flex-grow: 1;

    .title {

    }
  }
}
