.navbar-mobile {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 7vh;
  padding: 1vh 1vw 1vh 1vw;
  top: 0;
  width: 98vw;

  backdrop-filter: blur(2px);
  background-color: rgba(245, 245, 245, 0.8);
  box-shadow: 2px 5px 5px 0 rgba(128, 130, 75, 0.5);
  overflow: visible;
  user-select: none;
  z-index: 100;
  font-family: RalewayMedium, monospace;
  font-weight: normal;

  &.expanded {
    .navbar-item-list {
      pointer-events: all;

      .content {
        padding: 1vh 0 1.5vh 20vw;
        max-height: 30vh;
      }

      .overlay {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  i {
    padding: 0 0 0 2vw;
    top: 0;

    font-size: 5vh;
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }

  .navbar-logo {
    display: flex;

    img {
      height: 7vh;
    }
  }

  .navbar-languages {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    padding: 0 2vw 0 0;

    img {
      height: 3.5vh;

      filter: blur(0px);
      opacity: 1;
      transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;

      &.disabled {
        filter: blur(1px);
        opacity: .5;
      }
    }
  }

  .navbar-item-list {
    position: fixed;
    top: 9vh;
    height: 100vh;
    width: 100vw;
    left: 0;

    pointer-events: none;

    .content {
      height: 40vh;
      padding: 0 0 0 0;
      max-height: 0;

      backdrop-filter: blur(2px);
      background-color: rgba(245, 245, 245, 0.8);
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      div {
        display: flex;
        align-items: center;
        height: 5vh;

        font-size: 3vh;
      }
    }

    .overlay {
      margin-top: -1px;
      height: 91vh;
      width: 100vw;

      backdrop-filter: blur(2px);
      background-color: rgba(205, 205, 205, 0.5);
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
    }
  }
}
