.navbar-item {
  font-weight: bolder;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 0%;
    height: 2px;
    background-color: #d4b466;
    left: 50%;
    bottom: -1vh;
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    color: #d4b466;
    cursor: pointer;

    &::after {
      width: 100%;
      left: 0;
    }
  }
}
