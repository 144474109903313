.step-two-content-mobile {
  display: flex;
  flex-direction: column;
  user-select: none;
  overflow-x: hidden;
  gap: 1vh;

  background-color: whitesmoke;
  color: #80824b;
  justify-content: center;
  align-items: center;


  .step-two-title-mobile {
    font-size: 2.7vh;
    font-weight: bold;
    letter-spacing: 0.4vw;
    text-align: center;
    text-shadow: 0.5vw 0.5vw 0.5vw rgb(164, 154, 105, 0.9);
    padding: 4vh 0 2vh 0;
  }

    .choose-package-intro-mobile {
      font-size: 2vh;
      font-weight: bold;
      text-align: center;
      padding: 1vh 5vw 1vh 5vw;
    }

    input[type='radio'] + label {
      margin: 0 0 0 1vh;
    }


  .choose-package-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5vh;
    font-weight: bold;
    color: white;

    input.hidden {
      position: absolute;
      pointer-events: none;
      z-index: -2;
    }

    .choose-package-option-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 45vw;
      padding: 7vh 0 0 0;

      box-shadow: 0 0 0.75vh #80824b;

      line-height: 2vh;
      transition: all 0.5s ease-in-out;

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        pointer-events: none;
        transition: all 0.5s ease-in-out;
      }

      &.selection-made:after {
        backdrop-filter: grayscale(70%) blur(1px);
      }

      &.selected {
        outline: 2px solid whitesmoke;
        z-index: 1;

        &:after {
          backdrop-filter: grayscale(0%) blur(0px);
        }
      }

    }


    .ribbon-mobile {
      width: 160px;
      height: 80px;
      overflow: hidden;
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 1;

      &::before,
      &::after {
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
        border: 5px solid #424226;
        border-top-color: transparent;
        border-left-color: transparent;
      }

      &::before {
        top: 0;
        right: 0;
      }

      &::after {
        bottom: 0;
        left: 0;
      }

      span {
        position: absolute;
        display: block;
        width: 250px;
        padding: 2.5px 0;
        background-color: whitesmoke;
        color: #8d8f5d;
        font: 700 15px/1 RalewayMedium, monospace;
        text-shadow: 0 1px 1px rgb(236, 236, 236, 1);
        text-align: center;
        right: -25px;
        top: 30px;
        transform: rotate(-25deg);
        box-shadow: 0 2px 5px 0 rgba(66, 66, 38, 0.9);
        letter-spacing: 0.4vh;
      }
    }

    .package-info-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      flex-grow: 1;
      flex-basis: 0;
    }

    .package-element-largest-mobile {
      display: flex;
      flex-direction: row;
      padding: 1vh 0 0.5vh 0;
      font-size: 2vh;
    }

    .package-element-large-mobile {
      display: flex;
      flex-direction: row;
      gap: 0.5vw;
      padding: 1.5vh 0 0.5vh 0;
      font-size: 1.7vh;
      align-items: center;
      align-self: center;
      justify-items: center;
    }

    .package-element-small-mobile {
      display: flex;
      flex-direction: row;
      gap: 0.5vw;
      padding: 0 0 0 6vw;
      font-size: 1.4vh;
      align-items: center;
    }

    .package-price-group-mobile {
      display: flex;
      flex-direction: column;
      padding: 0 2vw 0 3vw;
      gap: 1vh;

      .price-package-mobile {
        font-size: 2.7vh;
        font-family: RalewayMedium, monospace;
        font-weight: normal;
      }

      .price-session-mobile {
        display: flex;
        flex-direction: row;
        font-size: 1.2vh;
        gap: 0.3vh;
        align-items: center;
        font-family: RalewayMedium, monospace;
        font-weight: normal;
      }
    }

    i {
      font-size: 1vh;
      opacity: 0.5;
    }

    .circle-sketch-1-mobile, .circle-sketch-2-mobile, .circle-sketch-3-mobile, .circle-sketch-4-mobile {
      position:relative;

      &::before {
        content: "";
        z-index: 1;
        border-width: 0.25vh;
        border-style: solid;
        border-color: whitesmoke;
        position: absolute;
        border-right-color: transparent;
        width: 100%;
        border-radius: 50%;
        height: 10vh;
      }

      &::after {
        content:"";
        z-index: 1;
        border-width: 0.25vh;
        border-style: solid;
        border-color: whitesmoke;
        border-left-color: transparent;
        border-top-color: transparent;
        position: absolute;
        width: 100%;
        border-radius: 50%;
        height: 8.5vh;
      }
    }

    .circle-sketch-1-mobile:before {
      left: -0.5vw;
      top: -2.3vh;
      transform: rotate(2deg);
    }

    .circle-sketch-1-mobile:after {
      left: -0.5vw;
      top: -2.05vh;
      transform: rotate(-1deg);
    }

    .circle-sketch-2-mobile:before {
      left: -0.5vw;
      top: -2.15vh;
      transform: rotate(-2deg);
    }

    .circle-sketch-2-mobile:after {
      left: -1.2vw;
      top: -2.3vh;
      transform: rotate(1deg);
    }

    .circle-sketch-3-mobile:before {
      left: -1vw;
      top: -3.1vh;
      transform: rotate(178deg);
    }

    .circle-sketch-3-mobile:after {
      left: -0vw;
      top: -1.45vh;
      transform: rotate(181deg);
    }

    .circle-sketch-4-mobile:before {
      left: -1vw;
      top: -2.7vh;
      transform: rotate(175deg);
    }

    .circle-sketch-4-mobile:after {
      left: -1vw;
      top: -1.55vh;
      transform: rotate(172deg);
    }


    .choose-package-top {
      display: flex;
      flex-direction: row;
      height: 32.5vh;

      .choose-express-mobile {
        background-color: #d9dac9;
        margin: 2vh 2vw 2vh 3vw;
      }

      .choose-lite-mobile {
        background-color: #c0c1a5;
        margin: 2vh 3vw 2vh 2vw;
      }
    }

    .choose-package-bottom {
      display: flex;
      flex-direction: row;
      height: 41vh;

      .choose-full-mobile {
        background-color: #a6a881;
        margin: 0 2vw 2vh 3vw;
      }

      .choose-beyond-mobile {
        background-color: #8d8f5d;
        margin: 0 3vw 2vh 2vw;
      }
    }
  }

  fieldset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #80824b;
    font-weight: bold;
    font-size: 1.3vh;
    background-color: white;
    margin: 0 5vw 0 5vw;
    width: 90vw;
    gap: 1vh;

    i {
      align-self: center;
    }

    .meeting-frequency-mobile {
      display: flex;
      flex-direction: column;
      padding: 0 5vw 0 2vw;
    }

    .meeting-location-mobile {
      display: flex;
      flex-direction: column;
      padding: 0 5vw 0 2vw;
    }

    .challenge-level-mobile {
      display: flex;
      flex-direction: column;
      padding: 0 5vw 0 2vw;
    }
  }
}

