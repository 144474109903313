
.testimonials-fullpage-mobile {
  .testimonials-fullpage-mobile-content {

    .content-mobile {
      display: flex;
      flex-direction: column;

      color: white;
      font-weight: bold;

      .testimonial-photo-name-country {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .testimonial-photo {
          width: 30vw;
          margin: 0 2vw 2vh 2vw;
          border: 1px solid white;
          border-radius: 2px 2px 2px 2px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            filter: drop-shadow(0 0 1vh white);
          }
        }

        .testimonial-name-country {
          align-self: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          margin: 1vh 0 1vh 2vw;
          font-weight: bold;

          .testimonial-name {
            align-self: center;
            justify-content: center;
            padding: 0 2vw 2vh 0;
            font-size: 3.5vh;
            text-shadow: 0.5vw 0.5vw 0.5vw rgb(236, 236, 236, 0.9);
          }

          .testimonial-country {
            align-self: center;
            justify-content: center;
            padding: 0 2vw 2vh 2vw;
            font-size: 2.8vh;
            text-shadow: 0.5vw 0.5vw 0.5vw rgb(236, 236, 236, 0.9);
          }
         }
      }

      .testimonial-text {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0 2vw 1vh 2vw;
        font-size: 2vh;
        gap: 1vh;
      }

      .jean-baptiste {
        padding: 2vh 2vw 2vh 2vw;
        margin: 2vh 3vw 2vh 3vw;
        background-color: #999b6f;
        filter: drop-shadow(5px 5px 3px #b3b8a7);
      }

      .sarah {
        padding: 2vh 2vw 2vh 2vw;
        margin: 2vh 3vw 2vh 3vw;
        background-color: #cccdb7;
        filter: drop-shadow(5px 5px 3px #b3b8a7);
      }

      .sia {
        padding: 2vh 2vw 2vh 2vw;
        margin: 2vh 3vw 2vh 3vw;
        background-color: #b3b493;
        filter: drop-shadow(5px 5px 3px #b3b8a7);
      }

      .katherina {
        padding: 2vh 2vw 2vh 2vw;
        margin: 2vh 3vw 2vh 3vw;
        background-color: #cccdb7;
        filter: drop-shadow(5px 5px 3px #b3b8a7);
      }

      .christina {
        padding: 2vh 2vw 2vh 2vw;
        margin: 2vh 3vw 2vh 3vw;
        background-color: #b3b493;
        filter: drop-shadow(5px 5px 3px #b3b8a7);
      }

      .franzi {
        padding: 2vh 2vw 2vh 2vw;
        margin: 2vh 3vw 2vh 3vw;
        background-color: #999b6f;
        filter: drop-shadow(5px 5px 3px #b3b8a7);
      }

      .simon {
        padding: 2vh 2vw 2vh 2vw;
        margin: 2vh 3vw 2vh 3vw;
        background-color: #cccdb7;
        filter: drop-shadow(5px 5px 3px #b3b8a7);
      }
    }
  }
}
