.step-three-content {


  .step-three-title {
    padding: 13vh 0 2vh 0;
    font-size: 3.5vw;
    font-weight: normal;
    letter-spacing: 0.4vw;
    text-align: center;
    text-shadow: 0.15vw 0.15vw 0.15vw rgb(164, 154, 105, 0.9);
  }

  .full-contact-form-intro {
    display: flex;
    flex-direction: column;
    padding: 0 0 1vh 0;

    font-size: 1.5vw;
    font-weight: bold;
    text-align: center;
  }

  .full-contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input, textarea {
      border: 1px solid #80824b;
      font-size: 0.8vw;

      &:focus-visible {
        outline-color: #80824b;
      }

      &:hover {
        cursor: text;
      }

      &::placeholder {
        color: #c0c1a5;
        font-weight: normal;
      }
    }

    input[type='checkbox'] + label,
    input[type='radio'] + label {
      margin: 0 0 0 0.5vw;
    }

    .name {
      display: flex;
      flex-direction: row;

      .first-name {
        padding: 1vh 1vw 0 0;

        input {
          padding: 0.75vh 2vh 0.75vh 2vh;
          border-radius: 10px 5px 5px 5px;
          width: 20vw;
        }
      }

      .last-name {
      padding: 1vh 0 0 0;

        input {
          padding: 0.75vh 2vh 0.75vh 2vh;
          border-radius: 5px 10px 5px 5px;
          width: 25vw;
        }
      }
    }

    .contact {
      display: flex;
      flex-direction: column;

      .email-phone {
        display: flex;
        flex-direction: row;

        .email {
          padding: 1vh 1vw 0 0;

          input {
            padding: 0.75vh 2vh 0.75vh 2vh;
            border-radius: 5px 5px 5px 5px;
            width: 27vw;
          }
        }

        .phone {
          padding: 1vh 0 0 0;

          input {
            padding: 0.75vh 2vh 0.75vh 2vh;
            border-radius: 5px 5px 5px 5px;
            width: 18vw;
          }
        }
      }

      .location {
        padding: 1vh 0 0 0;

        input {
          padding: 0.75vh 2vh 0.75vh 2vh;
          border-radius: 5px 5px 5px 5px;
          width: 48.5vw;
        }
      }

      .contact-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        fieldset {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-radius: 5px 5px 5px 5px;
          line-height: 3.2vh;
          border: 1px solid #80824b;
          font-weight: bold;
          font-size: 1vw;
          width: 49vw;
          background-color: white;
        }

        .contact-platforms {
          display: flex;
          flex-direction: column;
          padding: 0 0 0 2vw;
        }

        .contact-days {
          display: flex;
          flex-direction: column;
        }

        .contact-time {
          display: flex;
          flex-direction: column;
          padding: 0 4vw 0 0;
        }
      }
    }

    .further-info {
      display: flex;
      flex-direction: column;

      .previous-coaching {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        fieldset {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          border-radius: 5px 5px 5px 5px;
          line-height: 3.2vh;
          border: 1px solid #80824b;
          font-weight: bold;
          font-size: 1vw;
          width: 49vw;
          background-color: white;
        }

        .previous-coaching-yes {
          display: flex;
          flex-direction: column;
          margin: 0 0 0 -2vw;
        }

        .previous-coaching-no {
          display: flex;
          flex-direction: column;
        }
      }

      .current-coaching {
        padding: 1vh 0 0 0;

        textarea {
          padding: 0.75vh 2vh 0.75vh 2vh;
          border-radius: 5px 5px 5px 5px;
          width: 48.5vw;
          height: 7vh;
        }
      }

      .fun-fact {
        padding: 1vh 0 0 0;

        textarea {
          padding: 0.75vh 2vh 0.75vh 2vh;
          border-radius: 5px 5px 10px 10px;
          width: 48.5vw;
          height: 5vh;
        }

      }
    }

    .submit-form-button {
      align-self: center;

      button {
        margin: 2vh 0 5vh 0;

        background-color: whitesmoke;
        border: 1px solid #80824b;
        color: #80824b;
        width: 6.5vw;

        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      }

      :hover {
        background-color: #80824b;
        color: whitesmoke;
      }
    }
  }
}