@font-face {
  font-family: RalewayDots;
  src: url('/fonts/RalewayDots-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Megrim;
  src: url('/fonts/Megrim-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Barriecito;
  src: url('/fonts/Barriecito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: BungeeHairline;
  src: url('/fonts/BungeeHairline-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Kranky;
  src: url('/fonts/Kranky-Regular.ttf') format('truetype');
}

@font-face {
  font-family: PoiretOne;
  src: url('/fonts/PoiretOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: JosefinSlab;
  src: url('/fonts/JosefinSlab-Light.ttf') format('truetype');
}

@font-face {
  font-family: Quicksand;
  src: url('/fonts/Quicksand-Light.ttf') format('truetype');
}

@font-face {
  font-family: WireOne;
  src: url('/fonts/WireOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: RalewayThin;
  src: url('/fonts/Raleway-Thin.ttf') format('truetype');
}

@font-face {
  font-family: RalewayBold;
  src: url('/fonts/Raleway-Bold.ttf') format('truetype');
}

@font-face {
  font-family: RalewayExtraBold;
  src: url('/fonts/Raleway-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: RalewayMedium;
  src: url('/fonts/Raleway-Medium.ttf') format('truetype');
}

@font-face {
  font-family: AngellaWhite;
  src: url('/fonts/AngellaWhite.otf') format('truetype');
}

@font-face {
  font-family: DawningNewDay;
  src: url('/fonts/DawningofaNewDay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Delafield;
  src: url('/fonts/MrsSaintDelafield-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Sugiyama;
  src: url('/fonts/DrSugiyama-Regular.ttf') format('truetype');
}

@font-face {
  font-family: BethEllen;
  src: url('/fonts/BethEllen-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Windsong;
  src: url('/fonts/WindSong-Regular.ttf') format('truetype');
}

@font-face {
  font-family: WindsongMedium;
  src: url('/fonts/WindSong-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Bedfort;
  src: url('/fonts/MrBedfort-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Charmonman;
  src: url('/fonts/Charmonman-Regular.ttf') format('truetype');
}

@font-face {
  font-family: CharmonmanBold;
  src: url('/fonts/Charmonman-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Sacramento;
  src: url('/fonts/Sacramento-Regular.ttf') format('truetype');
}

html, body, #root, .main {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #e6e6db;
  font-family: 'RalewayThin', 'RalewayDots', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: unset;
  cursor: unset;
  text-decoration: unset;
}

p, h1, h2, h3, h4 {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

input {
  color: #80824b;
  font-family: RalewayMedium, monospace;
  letter-spacing: 0.2vw;
}

textarea {
  color: #80824b;
  font-family: RalewayMedium, monospace;
  letter-spacing: 0.2vw;
}

button {
  align-self: center;
  height: 3.5vh;
  margin: 3vh 0 1vh 0;

  border-radius: 10px 10px 10px 10px;
  color: whitesmoke;
  font-family: RalewayMedium, monospace;
  font-weight: bolder;
  font-size: 1vw;
  letter-spacing: 0.2vw;

  &:hover {
    cursor: pointer;
  }
}
