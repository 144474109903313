.bordered-image {
  position: relative;

  img {
    height: auto;
    max-width: 80%;
    max-height: 80%;
    margin: 3vh 3vw 3vh 3vw;
    position: relative;
    left: calc(50% - 3vw);

    transform: translate(-50%, 0);
  }

  .border {
    border-style: solid;
    color: transparent;
    position: absolute;
    bottom: calc(50% - 2vh);
    left: calc(50% - 2vw);

    transform: translate(-50%, 50%);
  }
}