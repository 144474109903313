.why-coaching-preview {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 6vw 8vh 3vw;
  user-select: none;

  .why-coaching-preview-image {
    max-width: 35%;
  }

  .why-coaching-preview-text {
    display: flex;
    flex-direction: column;
    max-width: 65%;
    font-weight: bold;

    .why-coaching-preview-title {
      font-size: 2.3vw;
      font-weight: normal;
      letter-spacing: 0.3vw;
      margin: 4vh 0 4vh 0;
      text-align: center;
      text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
    }

    .why-coaching-preview-description {
      font-size: 1.3vw;
      text-align: center;
      margin: 0 0 0 2vw;


      div {
        margin: 1vh 0 1vh 0;
        text-align: justify;
        text-align-last: center;
      }
    }
  }
}
