.home-testimonials-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #80824b;
  font-weight: bolder;
  user-select: none;

  .testimonials-preview-title {
    font-size: 4vh;
    letter-spacing: 0.3vw;
    margin: 5vh 0 2vh 0;
    padding: 2vh 2vw 2vh 2vw;
    text-align: center;
    text-shadow: 0 0 0.3vw rgb(75, 78, 49, 1);
    width: 100%;
  }

  .testimonials-preview-carousel {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    height: 40vh;
    width: 100vw;

    .fad {
      position: absolute;

      color: #80824b;
      cursor: pointer;
      font-size: 4vh;
      z-index: 1;

      &.fa-chevron-left {
        left: 4.5vw;
      }

      &.fa-chevron-right {
        right: 4.5vw;
      }
    }
  }

  .page-indicators {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    margin: 13vh 0 0 0;

    .page-indicator {
      width: 3vw;
      height: 3vw;

    }
  }

  button {
    margin: 3vh 0 3vh 0;
    font-size: 2vh;
    width: 37vw;

    background-color: whitesmoke;
    border: 1px solid #80824b;
    color: #80824b;
  }
}