.about-me-mobile {
  display: flex;
  flex-direction: column;
  margin: 0 5vw 5vh 5vw;

  .about-me-content-mobile {
    font-weight: bold;

    .introduction-mobile {
      margin: -3vh 0 0 0;

      .title {
        margin: 1vh 0 0.5vh 0;

        font-size: 3vh;
        font-weight: bold;
        letter-spacing: 0.3vw;
        text-align: center;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      }

      .subtitle {
        margin: 0 0 1vh 0;

        font-size: 2.5vh;
        font-weight: normal;
        letter-spacing: 0.3vw;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      }

      .bold-text {
        font-size: 2vh;
      }
    }

    .about-me-image-mobile {
      margin: 0 0 -1vh 0;
    }
  }

  .about-me-main-text-mobile {
    margin: 3vh 2vw 2vh 2vw;

    font-size: 2vh;
    font-weight: bold;
    text-align: justify;
  }
}
