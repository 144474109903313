.about-coaching-mobile {
  display: flex;
  flex-direction: column;
  margin: 0 5vw 5vh 5vw;

  .about-coaching-content-mobile {
    font-weight: bold;

    .introduction-mobile {
      font-size: 2vh;
      margin: 0 2vw 2vh 2vw;
      gap: 2vh;

      text-align: justify;
    }

    .about-coaching-image-mobile {
      margin: 2vh 0 0 0;
    }
  }

  .coaching-expectations-mobile {

    .title {
      margin: 3vh 0 3vh 0;

      font-size: 3vh;
      font-weight: bold;
      letter-spacing: 0.3vw;
      text-align: center;
      text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
    }

    .subtitle {
      margin: 0 0 1.5vh 0;

      font-size: 2.5vh;
      font-weight: normal;
      letter-spacing: 0.3vw;
      text-shadow: 0.2vh 0.2vh 0.2vh rgb(164, 154, 105, 0.9);
    }

    .text {
      margin: 3vh 2vw 2vh 2vw;

      font-size: 2vh;
      font-weight: bold;
      text-align: justify;
    }
  }
}