.about-me {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 0 10vh 0;

  .about-me-content {
    display: flex;
    flex-direction: row;

    font-weight: bold;
    gap: 2vh;
    text-align: justify;
    text-align-last: center;

    .introduction {
      display: flex;
      flex-direction: column;
      margin: 3vh 0 0 7vw;
      max-width: 55%;

      .title {
        font-size: 2.5vw;
        font-weight: bold;
        letter-spacing: 0.3vw;
        text-align: center;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      }

      .subtitle {
        font-size: 2.1vw;
        font-weight: normal;
        letter-spacing: 0.3vw;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
        text-align: center;
      }

      .bold-text {
        font-size: 2vw;
        font-weight: normal;
        text-shadow: 0.2vw 0.2vw 0.2vw rgb(164, 154, 105, 0.9);
      }
    }

    .about-me-image {
      margin: 0 7vw 0 0;
      max-width: 45%;
    }
  }

  .about-me-main-text {
    display: flex;
    flex-direction: column;
    margin: 3vh 10vw 0 8vw;
    gap: 2vh;

    font-size: 1.3vw;
    font-weight: bold;
    text-align: justify;
  }

}