
.modal {
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: all 1s;
  user-select: none;


  &.show {
    opacity: 1;

    .modal-box {
      pointer-events: all;
    }
  }

  &.collapsed {

    .modal-box {
      right: -16.7vw;

      .modal-expand {
        opacity: 1;
        pointer-events: all;
      }
    }

    .modal-text, .modal-title, button {
      opacity: 0;
    }
  }

  .modal-box {
    display: flex;
    position: absolute;
    flex-direction: column;
    padding: 3.2vh 2vw 3vh 2vw;
    margin: 0 0 10vh 0;
    border: 1px solid whitesmoke;
    width: 15.2vw;
    height: 25vh;
    justify-content: space-between;
    text-align: center;
    right: 0;

    background-color: #d4b466;
    border-radius: 15px 0 0 15px;
    color: whitesmoke;
    font-weight: bolder;
    filter: drop-shadow(0 0 1vh #80824b);

    transition: 0.5s;

    .modal-toggle {
      position: absolute;
      top: 1.2vh;

      color: white;
      cursor: pointer;
      font-size: 1.2vw;

      transition: 0.3s;

      &:hover {
        color: #80824b;
      }
    }

    .modal-collapse {
      right: 0.7vw;
    }

    .modal-expand {
      left: 0.7vw;
      opacity: 0;
      pointer-events: none;
    }

    .modal-title {
      font-size: 1.7vw;
      font-weight: bolder;
      padding: 0 0 1.5vh 0;

      transition: 0.5s;

      i {
        margin: 0 0 0 0.5vw;
        font-size: 2vw;
      }
    }

    .modal-text {
      font-size: 1.2vw;
      font-weight: bolder;

      transition: 0.5s;
    }

    .work-with-me-button {
      width: 10vw;

      background-color: #d4b466;
      border: 1px solid whitesmoke;
      color: whitesmoke;

      transition: 0.5s;

      &:hover {
        background-color: whitesmoke;
        border: 1px solid #d4b466;
        color: #d4b466;
      }
    }
  }
}
