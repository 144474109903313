.price-list-page-mobile {
  overflow-x: hidden;

  .price-list-content-mobile {
    display: flex;
    flex-direction: column;

    background-color: whitesmoke;
    color: #80824b;
    justify-content: center;
    align-items: center;
    line-height: 2vh;

    .prices-introduction-mobile {
      display: flex;
      flex-direction: column;
      margin: -4vh 7vw 0 7vw;
      gap: 1vh;

      font-size: 1.7vh;
      font-weight: bold;
      text-align: justify;
    }

    .packages-mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      font-weight: bolder;

      .choose-package-option-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 45vw;
        padding: 7vh 0 0 0;
        line-height: 2vh;

        box-shadow: 0 0 0.75vh #80824b;
      }


      .ribbon-mobile {
        width: 160px;
        height: 80px;
        overflow: hidden;
        position: absolute;
        top: -10px;
        left: -10px;

        &::before,
        &::after {
          position: absolute;
          z-index: -1;
          content: '';
          display: block;
          border: 5px solid #424226;
          border-top-color: transparent;
          border-left-color: transparent;
        }

        &::before {
          top: 0;
          right: 0;
        }

        &::after {
          bottom: 0;
          left: 0;
        }

        span {
          position: absolute;
          display: block;
          width: 250px;
          padding: 2.5px 0;
          background-color: whitesmoke;
          color: #8d8f5d;
          font: 700 15px/1 RalewayMedium, monospace;
          text-shadow: 0 1px 1px rgb(236, 236, 236, 1);
          text-align: center;
          right: -25px;
          top: 30px;
          transform: rotate(-25deg);
          box-shadow: 0 2px 5px 0 rgba(66, 66, 38, 0.9);
          letter-spacing: 0.4vh;
        }
      }

      .package-info-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        flex-grow: 1;
        flex-basis: 0;
      }

      .package-element-largest-mobile {
        display: flex;
        flex-direction: row;
        padding: 1vh 0 0.5vh 0;
        font-size: 2vh;
      }

      .package-element-large-mobile {
        display: flex;
        flex-direction: row;
        gap: 0.5vw;
        padding: 1.5vh 0 0.5vh 0;
        font-size: 1.7vh;
        align-items: center;
        align-self: center;
        justify-items: center;
      }

      .package-element-small-mobile {
        display: flex;
        flex-direction: row;
        gap: 0.5vw;
        padding: 0 0 0 6vw;
        font-size: 1.4vh;
        align-items: center;
      }

      .package-price-group-mobile {
        display: flex;
        flex-direction: column;
        padding: 0 2vw 0 3vw;
        gap: 1vh;

        .price-package-mobile {
          font-size: 2.7vh;
          font-family: RalewayMedium, monospace;
          font-weight: normal;
        }

        .price-session-mobile {
          display: flex;
          flex-direction: row;
          font-size: 1.2vh;
          gap: 0.3vh;
          align-items: center;
          font-family: RalewayMedium, monospace;
          font-weight: normal;
        }
      }

      i {
        font-size: 1vh;
        opacity: 0.5;
      }

      .circle-sketch-1-mobile, .circle-sketch-2-mobile, .circle-sketch-3-mobile, .circle-sketch-4-mobile {
        position:relative;

        &::before {
          content: "";
          z-index: 1;
          border-width: 0.25vh;
          border-style: solid;
          border-color: whitesmoke;
          position: absolute;
          border-right-color: transparent;
          width: 100%;
          border-radius: 50%;
          height: 10vh;
        }

        &::after {
          content:"";
          z-index: 1;
          border-width: 0.25vh;
          border-style: solid;
          border-color: whitesmoke;
          border-left-color: transparent;
          border-top-color: transparent;
          position: absolute;
          width: 100%;
          border-radius: 50%;
          height: 8.5vh;
        }
      }

      .circle-sketch-1-mobile:before {
        left: -0.5vw;
        top: -2.3vh;
        transform: rotate(2deg);
      }

      .circle-sketch-1-mobile:after {
        left: -0.5vw;
        top: -2.1vh;
        transform: rotate(-1deg);
      }

      .circle-sketch-2-mobile:before {
        left: -0.5vw;
        top: -2.2vh;
        transform: rotate(-2deg);
      }

      .circle-sketch-2-mobile:after {
        left: -1.2vw;
        top: -2.3vh;
        transform: rotate(1deg);
      }

      .circle-sketch-3-mobile:before {
        left: -1vw;
        top: -3.1vh;
        transform: rotate(178deg);
      }

      .circle-sketch-3-mobile:after {
        left: -0vw;
        top: -1.5vh;
        transform: rotate(181deg);
      }

      .circle-sketch-4-mobile:before {
        left: -1vw;
        top: -2.7vh;
        transform: rotate(175deg);
      }

      .circle-sketch-4-mobile:after {
        left: -1vw;
        top: -1.5vh;
        transform: rotate(172deg);
      }

      .choose-package-top {
        display: flex;
        flex-direction: row;
        height: 32.5vh;

        .package-express {
          background-color: #d9dac9;
          margin: 2vh 2vw 2vh 3vw;
        }

        .package-lite {
          background-color: #c0c1a5;
          margin: 2vh 3vw 2vh 2vw;
        }
      }

      .choose-package-bottom {
        display: flex;
        flex-direction: row;
        height: 41vh;

        .package-full {
          background-color: #a6a881;
          margin: 0 2vw 2vh 3vw;
        }

        .package-beyond {
          background-color: #8d8f5d;
          margin: 0 3vw 2vh 2vw;
        }
      }
    }

    .prices-faq-mobile {
      margin: 2vh 6vw 5vh 6vw;

      .question {
        margin: 3vh 0 1vh 0;

        font-size: 2.5vh;
        font-weight: normal;
        line-height: 3vh;
        letter-spacing: 0.3vw;
        text-shadow: 0.2vh 0.2vh 0.2vh rgb(164, 154, 105, 0.9);
      }

      .answer {
        margin: 0 3vw 1vh 3vw;

        font-size: 1.6vh;
        font-weight: bold;
        line-height: 2vh;
        text-align: justify;
      }
    }
  }
}
