
.post-tiles {
  flex-wrap: wrap;

  display: grid;
  grid-gap: 3vw;
  grid-template-columns: 15vw 15vw 15vw 15vw 15vw;
  grid-auto-rows: 15vw;

  &.bottom-line {
    margin: 0 0 5vh 0;
  }

  >:nth-child(1),
  >:nth-child(5),
  >:nth-child(11),
  >:nth-child(17) {
    transform: translateY(-3vw);
  }

  >:nth-child(2),
  >:nth-child(3),
  >:nth-child(6),
  >:nth-child(7),
  >:nth-child(12),
  >:nth-child(15),
  >:nth-child(16),
  >:nth-child(19) {
    transform: translateY(0vw);
  }

  >:nth-child(4),
  >:nth-child(8),
  >:nth-child(9),
  >:nth-child(10),
  >:nth-child(13),
  >:nth-child(14),
  >:nth-child(18),
  >:nth-child(20) {
    transform: translateY(3vw);
  }

  .first-row, .second-row {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;

    .left-side, .right-side {
      display: flex;
      flex-direction: column;
    }
  }
}
