.blog-page {

  .blog-page-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .content-box-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3vh;
      padding-bottom: 10vh;
    }
  }
}
